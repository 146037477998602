import url from '@/utils/URL'
import request from '@/utils/request'

export function enterprise(params) {
    return request({
      url: url.enterprise,
      method: 'POST',
      ...params
    })
}

export function enterpriseRule(params) {
    return request({
      url: url.enterpriseRule,
      method: 'POST',
      ...params
    })
}

export function enterpriseRuleTypes(params) {
    return request({
      url: url.enterpriseRuleTypes,
      method: 'POST',
      ...params
    })
}

export function enterpriseRuleAdd(params) {
    return request({
      url: url.enterpriseRuleAdd,
      method: 'POST',
      ...params
    })
}

export function enterpriseUpdate(params) {
    return request({
      url: url.enterpriseUpdate,
      method: 'POST',
      ...params
    })
}

export function enterpriseCompany(params) {
  return request({
    url: url.enterpriseCompany,
    method: 'POST',
    ...params
  })
}

export function enterpriseCompanyAdd(params) {
  return request({
    url: url.enterpriseCompanyAdd,
    method: 'POST',
    ...params
  })
}

export function enterpriseCompanyUPdate(params) {
  return request({
    url: url.enterpriseCompanyUPdate,
    method: 'POST',
    ...params
  })
}


export function enterpriseCompanyModule(params) {
  return request({
    url: url.enterpriseCompanyModule,
    method: 'POST',
    ...params
  })
}

export function enterpriseCompanyStatus(params) {
  return request({
    url: url.enterpriseCompanyStatus,
    method: 'POST',
    ...params
  })
}

export function enterpriseCompanyClean(params) {
  return request({
    url: url.enterpriseCompanyClean,
    method: 'POST',
    ...params
  })
}

export function enterpriseCompanyEnableModule(params) {
  return request({
    url: url.enterpriseCompanyEnableModule,
    method: 'POST',
    ...params
  })
}


export function enterpriseCompanyDisableModule(params) {
  return request({
    url: url.enterpriseCompanyDisableModule,
    method: 'POST',
    ...params
  })
}

export function enterpriseMarket(params) {
  return request({
    url: url.enterpriseMarket,
    method: 'POST',
    ...params
  })
}

export function enterpriseMarketAdd(params) {
  return request({
    url: url.enterpriseMarketAdd,
    method: 'POST',
    ...params
  })
}

export function enterpriseMarketUPdate(params) {
  return request({
    url: url.enterpriseMarketUPdate,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStock(params) {
  return request({
    url: url.enterpriseLabStock,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockList(params) {
  return request({
    url: url.enterpriseLabStockList,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockRsiList(params) {
  return request({
    url: url.enterpriseLabStockRsiList,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockListFsExport(params) {
  return request({
    url: url.enterpriseLabStockListFsExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function enterpriseLabStockHis(params) {
  return request({
    url: url.enterpriseLabStockHis,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockM5(params) {
  return request({
    url: url.enterpriseLabStockM5,
    method: 'POST',
   ...params
  })
}

export function enterpriseLabStockHisExport(params) {
  return request({
    url: url.enterpriseLabStockHisExport,
    method: 'POST',
    responseType: 'blob',
   ...params
  })
}

export function enterpriseLabStockBkList(params) {
  return request({
    url: url.enterpriseLabStockBkList,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockRelay(params) {
  return request({
    url: url.enterpriseLabStockRelay,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockShortList(params) {
  return request({
    url: url.enterpriseLabStockShortList,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockShoot(params) {
  return request({
    url: url.enterpriseLabStockShoot,
    method: 'POST',
    ...params
  })
}
export function enterpriseLabStockShootDel(params) {
  return request({
    url: url.enterpriseLabStockShootDel,
    method: 'POST',
    ...params
  })
}


export function enterpriseLabStockOpen(params) {
  return request({
    url: url.enterpriseLabStockOpen,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabIndex(params) {
  return request({
    url: url.enterpriseLabIndex,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabLiuru(params) {
  return request({
    url: url.enterpriseLabLiuru,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabDetail(params) {
  return request({
    url: url.enterpriseLabDetail,
    method: 'POST',
    ...params
  })
}

export function enterpriseLabStockKickTree(params) {
  return request({
    url: url.enterpriseLabStockKickTree,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatistics(params) {
  return request({
    url: url.enterpriseStatistics,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsCount(params) {
  return request({
    url: url.enterpriseStatisticsCount,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsPayment(params) {
  return request({
    url: url.enterpriseStatisticsPayment,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsDailyActive(params) {
  return request({
    url: url.enterpriseStatisticsDailyActive,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsBankType(params) {
  return request({
    url: url.enterpriseStatisticsBankType,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsFinance(params) {
  return request({
    url: url.enterpriseStatisticsFinance,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsBook(params) {
  return request({
    url: url.enterpriseStatisticsBook,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsNotification(params) {
  return request({
    url: url.enterpriseStatisticsNotification,
    method: 'POST',
    ...params
  })
}

export function enterpriseStatisticsDailyUserHeatmap(params) {
  return request({
    url: url.enterpriseStatisticsDailyUserHeatmap,
    method: 'POST',
    ...params
  })
}


export function enterpriseStatisticsDailyEmployeeHeatmap(params) {
  return request({
    url: url.enterpriseStatisticsDailyEmployeeHeatmap,
    method: 'POST',
    ...params
  })
}

export function enterpriseAccountLive(params) {
  return request({
    url: url.enterpriseAccountLive,
    method: 'POST',
    ...params
  })
}

export function enterpriseAccountIndex(params) {
  return request({
    url: url.enterpriseAccountIndex,
    method: 'POST',
    ...params
  })
}

export function enterpriseFiscal(params) {
  return request({
    url: url.enterpriseFiscal,
    method: 'POST',
    ...params
  })
}

export function enterpriseFiscalItem(params) {
  return request({
    url: url.enterpriseFiscalItem,
    method: 'POST',
    ...params
  })
}

export function enterpriseFiscalAdd(params) {
  return request({
    url: url.enterpriseFiscalAdd,
    method: 'POST',
    ...params
  })
}

export function enterpriseFiscalDel(params) {
  return request({
    url: url.enterpriseFiscalDel,
    method: 'POST',
    ...params
  })
}

export function enterpriseMonitorIndex(params) {
  return request({
    url: url.enterpriseMonitorIndex,
    method: 'POST',
    ...params
  })
}

export function enterpriseMonitorImage(params) {
  return request({
    url: url.enterpriseMonitorImage,
    method: 'POST',
    ...params
  })
}

export function enterpriseMonitorAdd(data) {
  return request({
    url: url.enterpriseMonitorAdd,
    method: 'POST',
    data
  })
}

export function enterpriseMonitorUpdate(data) {
  return request({
    url: url.enterpriseMonitorUpdate,
    method: 'POST',
    data
  })
}

export function enterpriseAccountLiveStudent(params) {
  return request({
    url: url.enterpriseAccountLiveStudent,
    method: 'POST',
    ...params
  })
}

export function enterpriseAccountStudentAccount(params) {
  return request({
    url: url.enterpriseAccountStudentAccount,
    method: 'POST',
    ...params
  })
}

export function enterpriseSystemClean(params) {
  return request({
    url: url.enterpriseSystemClean,
    method: 'POST',
    ...params
  })
}

export function enterpriseLogging(params) {
  return request({
    url: url.enterpriseLogging,
    method: 'POST',
    ...params
  })
}

export function enterpriseLoggingAccess(params) {
  return request({
    url: url.enterpriseLoggingAccess,
    method: 'POST',
    ...params
  })
}

export function enterpriseUpgradeLog(params) {
  return request({
    url: url.enterpriseUpgradeLog,
    method: 'POST',
    ...params
  })
}

export function enterpriseLoggingErrReport(params) {
  return request({
    url: url.enterpriseLoggingErrReport,
    method: 'POST',
    ...params
  })
}

export function enterpriseLoggingFlushErrReport(params) {
  return request({
    url: url.enterpriseLoggingFlushErrReport,
    method: 'POST',
    ...params
  })
}

export function enterpriseUpgradeLogAdd(params) {
  return request({
    url: url.enterpriseUpgradeLogAdd,
    method: 'POST',
    ...params
  })
}

export function enterpriseUpgradeLogUpdate(params) {
  return request({
    url: url.enterpriseUpgradeLogUpdate,
    method: 'POST',
    ...params
  })
}

export function enterpriseUpgradeLogDetail(params) {
  return request({
    url: url.enterpriseUpgradeLogDetail,
    method: 'POST',
    ...params
  })
}