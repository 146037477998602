const Layout = () => import('@/views/layout')
const Enterprise = () => import('@/views/enterprise')
const EnterpriseSon = () => import('@/views/enterprise/enterprise')
const EnterpriseApplication = () => import('@/views/enterprise/application')
const EnterpriseAuth = () => import('@/views/enterprise/auth')
const EnterpriseStatistics = () => import('@/views/enterprise/statistics')
const SettingHelper = () => import('@/views/enterprise/helper')
const SettingLogging = () => import('@/views/enterprise/logging')
const EnterpriseMonitor = () => import('@/views/enterprise/monitor')
const EnterpriseLab = () => import('@/views/enterprise/lab')
const EnterpriseBudget = () => import('@/views/enterprise/budget')
const EnterpriseAppModule = () => import('@/views/enterprise/appModule')
const EnterpriseLive = () => import('@/views/enterprise/live')

const enterpriseRouter = {
    path: '/enterprise',
    component: Layout,
    hide: true,
    parent:'enterpriseRouter',
    meta: {
      title: '企业管理',
      requiresAuth: false,
      type:'enterprise',
      icon: '#icon-SetOutline',
      menusKey:9
    },
    children:[
          {
            path: 'index',
            component: Enterprise,
            hide:false,
            parent:'setting',
            meta: { title: '企业管理' , icon: '#icon-DiamondOutline1' ,parent:'enterprise',}
          }, {
            path: 'enterprise',
            component: EnterpriseSon,
            hide: true,
            parent:'enterprise',
            meta: { title: '企业管理', icon: '#icon-DiamondOutline1' ,parent:'enterprise',},
          }, {
            path: 'enterpriseAccountLive',
            component: EnterpriseLive,
            hide: true,
            parent:'enterprise',
            meta: { title: '在线账户', icon: '#icon-IdentitycardOutline' ,parent:'enterprise',},
          }, {
            path: 'enterpriseMonitor',
            component: EnterpriseMonitor,
            hide: true,
            parent:'enterprise',
            meta: { title: '实况管理', icon: '#icon-ChipOutline1' ,parent:'enterprise',},
          }, {
            path: 'enterpriseApplication',
            component: EnterpriseApplication,
            hide: true,
            parent:'enterprise',
            meta: { title: '应用管理', icon: '#icon-ChipOutline1' ,parent:'enterprise',},
          }, {
            path: 'enterpriseAuth',
            component: EnterpriseAuth,
            hide: true,
            parent:'enterprise',
            meta: { title: '权限管理', icon: '#icon-RelationOutline' ,parent:'enterprise',},
          }, {
            path: 'enterpriseStatistics',
            component: EnterpriseStatistics,
            hide: true,
            parent:'enterprise',
            meta: { title: '企业统计', icon: '#icon-NarrateOutline' ,parent:'enterprise',},
          }, {
            path: 'helper',
            component: SettingHelper,
            hide:true,
            parent:'enterprise',
            meta: { title: '帮助中心' , icon: '#icon-QueryOutline' ,parent:'enterprise',}
          }, {
            path: 'logging',
            component: SettingLogging,
            hide:true,
            parent:'enterprise',
            meta: { title: '操作日志' , icon: '#icon-QueryOutline' ,parent:'enterprise',}
          }, {
            path: 'lab',
            component: EnterpriseLab,
            hide:true,
            parent:'enterprise',
            meta: { title: '实验室' , icon: '#icon-QueryOutline' ,parent:'enterprise',}
          },{
            path: 'ace',
            component: EnterpriseBudget,
            hide:true,
            parent:'enterprise',
            meta: { title: 'ace' , icon: '#icon-QueryOutline' ,parent:'enterprise',}
          },{
            path: 'appModule/:id',
            component: EnterpriseAppModule,
            hide:true,
            parent:'enterprise',
            meta: { title: '模块列表' , icon: '#icon-QueryOutline' ,parent:'enterprise',}
          }
    ]
}

export default enterpriseRouter