const url = {
  uploadQiNiu: 'https://up.qiniup.com',
  imageRoot: 'http://i01.wedots.cn/',
  fileRoot: 'http://v01.wedots.cn/',
  wxapp: 'http://wxapp.wedots.cn',

  login: '/v2b/auth/login',
  faceLogin:'/v2b/auth/face-login',
  qrLogin: '/v2b/auth/qr-login',
  wxworkQrLogin: '/v2b/wxwork/qr-login/callback',
  logout: '/v2b/auth/logout',
  forgePassword: '/v2b/auth/forge-password',
  register: '/auth/register',
  loginQrcode: '/v2b/auth/login-qrcode',
  sendSms: '/account/sms',
  sendSmsErr: '/account/sms_err',
  getAuth: '/v2b/account/profile/permission',
  // get my info
  userInfo: '/v2b/auth/info',
  controlPanel_request: '/v2b/controlPanel/index',

  // 账户资料
  accout: '/v2b/account/profile/index',
  accoutUpdate: '/v2b/account/profile/update',
  accoutSubordinate: '/v2b/account/profile/subordinate',
  accoutChangePassword: '/v2b/account/profile/change-password',

  /** 搜索 */
  // 学员/班级搜索
  searchEngine: '/v2b/search/engine/index',
  // 员工搜索
  searchEmployee: '/v2b/search/engine/employee',
  // 班级搜索
  searchEngineClasses: '/v2b/search/engine/classes',
  // 学员搜索
  searchStudent: '/v2b/search/engine/student',
  searchWarehouse: '/v2b/search/filter/get-warehouse',
  searchWarehouseProduct: '/v2b/search/filter/get-warehouse-product',
  searchStudentContactType: '/v2b/search/filter/get-student-contact-type',
  // 获取课程付费方式
  searchFeeType: '/v2b/search/filter/get-fee-type',
  searchTradeType: '/v2b/search/filter/get-trade-type',
  // 获取支付方式
  searchPaymentType: '/v2b/search/filter/get-payment-type',
  // 获取交易校区(有权限)
  searchBelongStudio: '/v2b/search/filter/get-belong-studio',
  // 获取交易校区 
  searchStudio: '/v2b/search/filter/get-studio',
  // 获取商品
  searchProduct: '/v2b/search/filter/get-product',
  //获取供应商
  searchSuppliers: '/v2b/search/filter/get-suppliers',
  // 获取所有交易校区 
  searchAllStudio: '/v2b/search/filter/get-all-studio',
  // 获取校区教室 
  searchStudioRoom: '/v2b/search/filter/get-room',
  // 来源渠道搜索 
  searchSourceChannel: '/v2b/search/filter/get-source-channel',
  // 来源渠道搜索
  searchSourceChannelCascader: '/v2b/search/filter/get-source-channel-cascader',
  // 课程名称搜索 
  searchCourseType: '/v2b/search/filter/get-course-type',
  // 课程名称搜索 
  searchCourse: '/v2b/search/filter/get-course',
  // Demo课程名称搜索 
  searchDemoCourse: '/v2b/search/filter/get-demo-course',
  // 资料搜索 
  searchLibrary: '/v2b/search/filter/get-library',
  // 资料分类搜索 
  searchLibraryCascader: '/v2b/search/filter/get-library-cascader',
  // 标签搜索
  searchGetTags: '/v2b/search/filter/get-tags',
  // 资料分类搜索 
  searchLibraryCategory: '/v2b/search/filter/get-library-category',
  // 档案分类
  searchMessageType: '/v2b/search/filter/get-message-type',
  // 帮助分类搜索
  searchHelperType: '/v2b/search/filter/get-helper-type',
  // 合同类型搜索
  searchContractType: '/v2b/search/filter/get-contract-type',
  // 经办人搜索
  searchCreatedBy: '/v2b/search/filter/get-created-by',
  // 课程顾问/班主任
  searchConsultant: '/v2b/search/filter/get-search-consultant',
  // 授课老师
  searchAccountEmployee: '/v2b/search/filter/get-search-employee',
  // 授课老师
  searchTeacher: '/v2b/search/filter/get-search-teacher',
  searchTeacherCascader: '/v2b/search/filter/get-search-teacher-cascader',
  // 所属班级
  searchClasses: '/v2b/search/filter/get-search-classes',
  // 所属活动过搜索
  searchMeeting: '/v2b/search/filter/get-search-meeting',
  // 所属活动的票务类型
  searchTicketType: '/v2b/search/filter/get-search-ticket-type',
  // 学员
  searchStudents: '/v2b/search/filter/get-search-students',
  // 搜索成员
  searchQyweEmployee: '/v2b/search/filter/get-search-qywx-employee',
  // 课程内容
  searchKnowledgeContent: '/v2b/search/filter/get-knowledge-content',
  // 课程专栏
  searchKnowledgePackage: '/v2b/search/filter/get-knowledge-package',
  // 校区课程收费
  searchCourseFee: '/v2b/search/filter/get-course-fee',
  // 在线招募课程收费
  searchClassRecruitFee: '/v2b/search/filter/get-class-recruit-fee',
  // 其他物品收费
  searchIncidentalFee: '/v2b/search/filter/get-incidental-fee',
  // 账户角色 
  searchRole: '/v2b/search/filter/get-role',
  // 信息采集
  searchSurvey: '/v2b/search/filter/get-survey',
  //班级搜索弹窗
  searchClassesList: '/v2b/search/filter/get-classes-list',
  // 获取票务类型
  searchCampaignTicketType: '/v2b/search/filter/get-campaign-ticket-type',
  // 获取年级
  searchGetGradeType: '/v2b/search/filter/get-grade-type',
  // 搜索部门
  searchGetDepartment: '/v2b/search/filter/get-department',
  // 搜索职位
  searchPosition: '/v2b/search/filter/get-search-position',
  // 搜索客户
  searchCustomer: '/v2b/search/filter/get-search-customer',

  // 教务中心
  affairs: '/v2b/menu/affairs/index',
  // 校区管理
  orginization: '/v2b/menu/orginization/index',
  studio_index: '/v2b/studio/admin/index',
  studio_off: '/v2b/studio/admin/delete',
  studio_on: '/v2b/studio/admin/recovery',
  studio_detail: '/v2b/studio/admin/detail',
  studio_update: '/v2b/studio/admin/update',
  studio_add: '/v2b/studio/admin/add',
  // 市场应用
  market: '/v2b/menu/market/index',
  poolIndex: '/v2b/pool/pool/index',
  poolTransfer: '/v2b/pool/pool/transfer',
  poolExport: '/v2b/pool/pool/export',
  // 企业管理
  enterprise: '/v2b/enterprise/menu/manager',
  enterpriseRuleTypes: '/v2b/enterprise/access-rule/types',
  enterpriseRule: '/v2b/enterprise/access-rule/index',
  enterpriseRuleAdd: '/v2b/enterprise/access-rule/add',
  enterpriseUpdate: '/v2b/enterprise/access-rule/update',
  enterpriseCompany: '/v2b/enterprise/company/index',
  enterpriseCompanyAdd: '/v2b/enterprise/company/add',
  enterpriseCompanyUPdate: '/v2b/enterprise/company/update',
  enterpriseCompanyModule: '/v2b/enterprise/company/module',
  enterpriseCompanyStatus: '/v2b/enterprise/company/status',
  enterpriseCompanyClean: '/v2b/enterprise/company/clean',
  enterpriseCompanyEnableModule: '/v2b/enterprise/company/enable-module',
  enterpriseCompanyDisableModule: '/v2b/enterprise/company/disable-module',
  enterpriseMarket: '/v2b/enterprise/market/index',
  enterpriseMarketAdd: '/v2b/enterprise/market/add',
  enterpriseMarketUPdate: '/v2b/enterprise/market/update',
  enterpriseStatistics: '/v2b/enterprise/statistics/index',
  enterpriseStatisticsCount: '/v2b/enterprise/statistics/count',
  enterpriseStatisticsPayment: '/v2b/enterprise/statistics/payment',
  enterpriseStatisticsDailyActive: '/v2b/enterprise/statistics/daily-active-user',
  enterpriseStatisticsBankType: '/v2b/enterprise/statistics/bank-type',
  enterpriseStatisticsFinance: '/v2b/enterprise/statistics/finance',
  enterpriseStatisticsBook: '/v2b/enterprise/statistics/book',
  enterpriseStatisticsNotification: '/v2b/enterprise/statistics/notification',

  enterpriseStatisticsDailyUserHeatmap: '/v2b/enterprise/statistics/daily-user-heatmap',
  enterpriseStatisticsDailyEmployeeHeatmap: '/v2b/enterprise/statistics/daily-employee-heatmap',

  enterpriseAccountLive: '/v2b/enterprise/account/live',
  enterpriseAccountIndex: '/v2b/enterprise/account/index',
  enterpriseMonitorIndex: '/v2b/enterprise/monitor/index',
  enterpriseMonitorImage: '/v2b/enterprise/monitor/image',

  enterpriseFiscal: '/v2b/enterprise/fiscal/index',
  enterpriseFiscalItem: '/v2b/enterprise/fiscal/item',
  enterpriseFiscalAdd: '/v2b/enterprise/fiscal/add',
  enterpriseFiscalDel: '/v2b/enterprise/fiscal/del',

  enterpriseLabIndex: '/v2b/enterprise/lab/index',
  enterpriseLabLiuru: '/v2b/enterprise/lab/liuru',
  enterpriseLabDetail: '/v2b/enterprise/lab/detail',
  enterpriseLabStockKickTree: '/v2b/labs/stock/kick-tree',

  enterpriseLabStock: '/v2b/labs/stock/analysis',
  enterpriseLabStockList: '/v2b/labs/stock/list',
  enterpriseLabStockListFsExport: '/v2b/labs/stock/fs-export',
  enterpriseLabStockRsiList:'/v2b/enterprise/lab/rsi-list',
  enterpriseLabStockHis: '/v2b/labs/stock/his',
  enterpriseLabStockM5: '/v2b/labs/stock/m5',
  enterpriseLabStockHisExport: '/v2b/labs/stock/his-export',
  enterpriseLabStockBkList: '/v2b/labs/stock/bk-list',
  enterpriseLabStockShoot: '/v2b/labs/stock/reminder',
  enterpriseLabStockShootDel: '/v2b/labs/stock/reminder-del',
  enterpriseLabStockRelay: '/v2b/labs/stock/relay',
  enterpriseLabStockShortList: '/v2b/labs/stock/short-list',
  enterpriseLabStockOpen: '/v2b/labs/stock/open',

  enterpriseMonitorAdd: '/v2b/enterprise/monitor/add',
  enterpriseMonitorUpdate: '/v2b/enterprise/monitor/update',

  enterpriseAccountLiveStudent: '/v2b/enterprise/account/live-student',
  enterpriseAccountStudentAccount: '/v2b/enterprise/account/student-account',
  enterpriseSystemClean: '/v2b/enterprise/system/clean-cache',
  enterpriseLogging: '/v2b/enterprise/logging/index',
  enterpriseLoggingAccess: '/v2b/enterprise/logging/access',
  enterpriseLoggingErrReport: '/v2b/enterprise/logging/err-report',
  enterpriseLoggingFlushErrReport: '/v2b/enterprise/logging/flush-err-report',
  enterpriseUpgradeLog: '/v2b/enterprise/upgrade-log/index',
  enterpriseUpgradeLogAdd: '/v2b/enterprise/upgrade-log/add',
  enterpriseUpgradeLogUpdate: '/v2b/enterprise/upgrade-log/update',
  enterpriseUpgradeLogDetail: '/v2b/enterprise/upgrade-log/detail',
  

  // 素材管理`

  material: '/v2b/material/material/index',
  materialUpToken: '/v2b/material/material/uptoken',
  materialVideoToken: '/v2b/material/material/video-token',
  materialFolder: '/v2b/material/material/folder',
  materialAjaxAddFolder: '/v2b/material/material/ajax-add-folder',
  materialAjaxDelete: '/v2b/material/material/delete',
  materialDelete: '/v2b/material/material/delete',
  materialUpload: '/v2b/material/material/upload',
  // 获取附件上传Token
  massToken: '/v2b/material/material/mass-token',
  // 获取图片上传Token
  imageToken: '/v2b/material/material/image-token',


  // 资料共享
  zlShare: '/v2b/xuetang/library/index',
  // 资料分类 @TODO 重复
  zlCategory: '/v2b/xuetang/library/category',
  // 添加资料分类
  zlCategoryAdd: '/v2b/xuetang/library/category-add',
  // 编辑资料分类
  zlCategoryEdit: '/v2b/xuetang/library/category-edit',
  // 删除资料分类
  zlCategoryDelete: '/v2b/xuetang/library/category-delete',
  // 添加资料
  zlShareAdd: '/v2b/xuetang/library/add',
  // 编辑资料
  zlShareEdit: '/v2b/xuetang/library/update',
  // 删除资料
  zlShareDelete: '/v2b/xuetang/library/delete',
  // 资料详情
  zlShareDetail: '/v2b/xuetang/library/detail',
  // 文件转移
  zlShareMoveTo: '/v2b/xuetang/library/move-to',
  // 文件排序
  zlShareChangeOrder: '/v2b/xuetang/library/change-order',

  /** 档案 */
  // 学员档案
  daMessages: '/v2b/xuetang/messages/index',
  // 班级档案
  daMessagesClasses: '/v2b/xuetang/messages/classes',
  // 档案添加
  daMessagesPublish: '/v2b/xuetang/messages/publish',
  // 添加成长档案分类
  daMessagesAddCate: '/v2b/xuetang/messages/add-cate',
  // 学员成长档案
  daMessagesDetail: '/v2b/xuetang/messages/detail',
  // 档案置顶
  daMessagesRecommand: '/v2b/xuetang/messages/recommand',
  // 修改成长档案分类
  daMessagesUpdateCate: '/v2b/xuetang/messages/update-cate',
  // 档案状态
  daMessagesStatus: '/v2b/xuetang/messages/status',
  // 删除成长档案分类
  daMessagesDeleteCate: '/v2b/xuetang/messages/delete-cate',
  // 恢复成长档案分类
  daMessagesRecoveryCate: '/v2b/xuetang/messages/recovery-cate',
  // 删除档案
  daMessagesDelete: '/v2b/xuetang/messages/delete-message',
  // 恢复档案
  daMessagesRecovery: '/v2b/xuetang/messages/recovery-message',

  xtContact: '/v2b/xuetang/contact/index',
  xtContactDetail: '/v2b/xuetang/contact/detail',

  // 作业
  homework: '/v2b/xuetang/homework/index',
  homeworkPublish: '/v2b/xuetang/homework/publish',
  homeworkClasses: '/v2b/xuetang/homework/classes',
  homeworkDetail: '/v2b/xuetang/homework/detail',
  homeworkReissue: '/v2b/xuetang/homework/reissue',
  homeworkResend: '/v2b/xuetang/homework/resend',
  homeworkRecord: '/v2b/xuetang/homework/record',
  homeworkRate: '/v2b/xuetang/homework/rate',
  homeworkAddComment: '/v2b/xuetang/homework/add-comment',
  homeworkAddReply: '/v2b/xuetang/homework/add-reply',
  homeworkUpdateReply: '/v2b/xuetang/homework/update-reply',
  homeworkDeleteComment: '/v2b/xuetang/homework/delete-comment',
  homeworkDelete: '/v2b/xuetang/homework/delete',
  homeworkReceipted: '/v2b/xuetang/homework/receipted',

  // 帮助
  xtHelp: '/v2b/xuetang/wxapp-help/index',
  xtHelpCateAdd: '/v2b/xuetang/wxapp-help/category-add',
  xtHelpAdd: '/v2b/xuetang/wxapp-help/helper-add',
  xtHelpView: '/v2b/xuetang/wxapp-help/helper-view',
  xtHelpEdit: '/v2b/xuetang/wxapp-help/helper-edit',
  xtHelpCateEdit: '/v2b/xuetang/wxapp-help/category-edit',
  xtHelpCateDelete: '/v2b/xuetang/wxapp-help/category-delete', 

  // 班级管理
  classesList: '/v2b/classes/classes/index',
  classesAdd: '/v2b/classes/classes/add',
  classesUpdate: '/v2b/classes/classes/update',
  classesDetail: '/v2b/classes/classes/detail',
  classesExport: '/v2b/classes/classes/export',
  classesClose: '/v2b/classes/classes/close',
  classesAttend: '/v2b/classes/classes/attend',
  classesWxworkStaff: '/v2b/classes/classes/wxwork-staff',
  classesWxworkContact: '/v2b/classes/classes/wxwork-contact',
  classesWxworkMsg: '/v2b/classes/classes/wxwork-msg',
  classesMerge: '/v2b/classes/classes/merge',
  classesClassesAlbum: '/v2b/classes/classes/album',
  // 排课批量处理
  classesMultiSetting: '/v2b/classes/classes/multi-setting',
  classesMember: '/v2b/classes/classes/member',
  classesMemberAdd: '/v2b/classes/classes/add-member',
  classesMemberDelete: '/v2b/classes/classes/delete-member',
  // 可进班级学员
  classesMemberPotential: '/v2b/classes/classes/potential-member',
  // 类似班级
  classesActiveClass: '/v2b/classes/classes/active-class',
  // 班级学员进出记录
  classesMemberRecord: '/v2b/classes/classes/member-record',
  // 班级开课记录
  classesSchedule: '/v2b/classes/classes/schedule',
  classesScheduleAdd: '/v2b/classes/classes/add-schedule',
  classesScheduleAddDate: '/v2b/classes/classes/add-schedule-by-date',
  classesCancelCourse: '/v2b/classes/classes/cancel-course',
  // 班级管理员
  classesManager: '/v2b/classes/classes/manager',
  classesManagerAdd: '/v2b/classes/classes/add-manager',
  classesManagerRemove: '/v2b/classes/classes/remove-manager',
  classesConfirmApply: '/v2b/classes/classes/confirm-apply',
  classesDeleteApply: '/v2b/classes/classes/delete-apply',
  classesSubstitute: '/v2b/classes/classes/substitute',
  // classesAddTempMember: '/v2b/classes/classes/add-temp-member',
  classesAddTempMember: '/v2b/course/schedule/add-member',

  // 班级相册
  classesAlbum: '/v2b/classes/album/index',
  classesAlbumImages: '/v2b/classes/album/images',
  classesAlbumDeleteImages: '/v2b/classes/album/delete-images',
  classesAlbumImagesShow: '/v2b/classes/album/images-show',
  classesAlbumAdd: '/v2b/classes/album/add',
  classesAlbumUpdate: '/v2b/classes/album/update',
  classesAlbumDelete: '/v2b/classes/album/delete',
  classesAlbumUpload: '/v2b/classes/album/upload',
  

  // 校区列表
  studioList: '/v2b/studio/center/index',
  studioDetail: '/v2b/studio/center/detail',
  studioUpdate: '/v2b/studio/center/update',
  studioAdd: '/v2b/studio/center/add',
  studioDelete: '/v2b/studio/center/delete',
  studioRecovery: '/v2b/studio/center/recovery',
  studioChangeOrder: '/v2b/studio/center/change-order',
  // 下载校区二维码
  studioDownload: '/v2b/studio/center/download',
  // 查看二维码
  studioQrcodeview: '/v2b/studio/center/qrcodeview',

  // 意见反馈列表
  studioSuggestion: '/v2b/studio/suggestion/index',
  studioSuggestionDetail: '/v2b/studio/suggestion/detail',
  studioSuggestionRead: '/v2b/studio/suggestion/read',
  studioSuggestionMultiAction: '/v2b/studio/suggestion/multi-action',

  // 续费预警
  extendingAlert: '/v2b/extending/alert/index',
  extendingLimit: '/v2b/extending/limit/index',

  // 课程科目
  cCategoryList: '/v2b/course/category/index',
  cCategoryAdd: '/v2b/course/category/add',
  cCategoryStudios: '/v2b/course/category/studios',
  cCategoryUpdate: '/v2b/course/category/update',
  cCategoryDetail: '/v2b/course/category/detail',
  cCategoryDelete: '/v2b/course/category/delete',
  cCategoryRecovery: '/v2b/course/category/recovery',
  cCategoryCoursesSingleFee: '/v2b/course/category/course-single-fee',
  cCategoryCoursesList: '/v2b/course/category/courses',
  cCategoryCoursesAdd: '/v2b/course/category/course-add',
  cCategoryCoursesUpdate: '/v2b/course/category/course-update',
  cCategoryCoursesDelete: '/v2b/course/category/courses-delete',
  cCategoryCoursesRecovery: '/v2b/course/category/courses-recovery',

  // 在籍学员
  students: '/v2b/students/students/index',
  studentsDetail: '/v2b/students/students/detail',
  studentsSetFace: '/v2b/students/students/set-face',
  studentsAdd: '/v2b/students/students/add',
  studentsUpdate: '/v2b/students/students/update',
  studentsDelete: '/v2b/students/students/delete',
  studentsHistory: '/v2b/students/students/history',
  studentsMerge: '/v2b/students/students/merge',
  studentsWeixinQrcode: '/v2b/students/students/weixin-qrcode',
  studentsExport: '/v2b/students/students/export',
  studentsCoupons: '/v2b/students/profile/coupons',
  // 潜在学员
  studentsPotential: '/v2b/students/students/potential',
  // 多学员选择页面
  studentsStudentCheck: '/v2b/students/students/student-check',
  studentsRecovery: '/v2b/students/students/recovery',
  studentsUpdateStatus: '/v2b/students/students/update-status',
  studentsStudentsFilter: '/v2b/students/students-filter/index',

  // 待排班学员
  pendding: '/v2b/pendding/pendding/index',

  // 通知中心
  notificationNotice: '/v2b/notification/notice/index',
  notificationCenter: '/v2b/notification/center/index',
  notificationCenterAdd: '/v2b/notification/center/add',
  notificationCenterUpdate: '/v2b/notification/center/update',
  notificationCenterDetail: '/v2b/notification/center/detail',
  notificationCenterDelete: '/v2b/notification/center/delete',
  notificationCenterRecovery: '/v2b/notification/center/recovery',
  notificationCenterResend: '/v2b/notification/center/resend',
  notificationCenterNotifyList: '/v2b/notification/center/notify-list',
  notificationCenterAjaxGetUser: '/v2b/notification/center/ajax-get-user',
  notificationOverview: '/v2b/notification/overview/index',

  //消息通知
  notificationIndex: '/v2b/notification/notice/index',
  notificationUnread: '/v2b/notification/notice/unread',
  notificationSeen: '/v2b/notification/notice/seen',
  notificationSeenAll: '/v2b/notification/notice/seen-all',


  // 学员评价列表
  rateStudent: '/v2b/rate/student/index',
  rateTeacher: '/v2b/rate/teacher/index',
  rateStudentCheck: '/v2b/rate/comment/check',
  rateStudentDelete: '/v2b/rate/comment/delete',
  rateStudentDetail: '/v2b/rate/teacher/detail',
  rateStudentAddComment: '/v2b/rate/teacher/add-comment',
  rateStudentAjaxView: '/v2b/rate/student/ajax-view',
  rateStudentAjaxViewComment: '/v2b/rate/student/ajax-view-comment',
  rateStudentCourseView: '/v2b/rate/student/course-view',
  rateStudentMultiAction: '/v2b/rate/student/multi-action',
  rateStudentAdd: '/v2b/rate/student/add',

  // 学员信息
  studentsProfile: '/v2b/students/profile/index',
  studentsProfileCredit: '/v2b/students/profile/credit',
  studentsProfileCosts: '/v2b/students/profile/costs',
  studentsProfileCourseClass: '/v2b/students/profile/course-class',
  studentsProfileCancelClass: '/v2b/students/profile/cancel-class',
  studentsProfileBookCosts: '/v2b/students/profile/book-costs',
  studentsProfileClasses: '/v2b/students/profile/classes',
  studentsProfileContact: '/v2b/students/profile/contact',
  studentsProfileAddContact: '/v2b/students/profile/add-contact',
  studentsProfileContactDetail: '/v2b/students/profile/contact-detail',
  studentsProfileUpdateContact: '/v2b/students/profile/update-contact',
  studentsProfileHomework: '/v2b/students/profile/homework',
  studentsProfileDetail: '/v2b/students/profile/detail',
  studentsProfilePendding: '/v2b/students/profile/pendding',
  studentsProfileRefresh: '/v2b/students/profile/refresh',
  studentsProfileCertificate: '/v2b/students/profile/certificate',
  studentsProfileWallet: '/v2b/students/profile/wallet',
  studentsProfileWalletContract: '/v2b/students/profile/wallet-contract',
  studentsProfileContract: '/v2b/students/profile/contract',
  studentsProfileContractRefund: '/v2b/students/profile/contract-refund',
  studentsProfileCanJoinClasses: '/v2b/students/profile/can-join-classes',
  studentsProfileAddToClasses: '/v2b/students/profile/add-to-class',
  studentsAddCredit: '/v2b/credit/transaction/add-credit',
  studentsMinusCredit: '/v2b/credit/transaction/minus-credit',


  // 课程排课
  courseSchedule: '/v2b/course/schedule/index',

  //deviceScanIndex
  deviceScanIndex: '/v2b/device/scan/index',
  deviceScanCheckin: '/v2b/device/scan/checkin',
  deviceScanCheckinList: '/v2b/device/scan/checkin-list',
  deviceScanEvents: '/v2b/device/scan/events',
  deviceScanPayment: '/v2b/device/scan/payment',

  courseClipIndex: '/v2b/course/clip/index',
  courseClipDelete: '/v2b/course/clip/delete',
  courseRecallIndex: '/v2b/course/recall/index',
  courseRecallClip: '/v2b/course/recall/clip',
  courseRecallFinish:'/v2b/course/recall/finish',
  
  courseScheduleAddSchedule: '/v2b/course/schedule/add-schedule',
  courseScheduleCalendar: '/v2b/course/schedule/calendar',
  courseScheduleDetail: '/v2b/course/schedule/detail',
  courseScheduleLiveRecord: '/v2b/course/schedule/live-record',
  courseScheduleHighlight: '/v2b/course/schedule/highlight',
  courseScheduleDelHighlight: '/v2b/course/schedule/del-highlight',
  courseScheduleAddHighlight: '/v2b/course/schedule/add-highlight',
  courseScheduleMultiSetting: '/v2b/course/schedule/multi-setting',
  courseScheduleSubstitute: '/v2b/course/schedule/substitute',
  courseScheduleAdd: '/v2b/course/schedule/add',
  courseScheduleExport: '/v2b/course/schedule/export',
  courseScheduleCancelCourse: '/v2b/course/schedule/cancel-course',
  courseScheduleNextday: '/v2b/course/schedule/next-day',
  courseScheduleBookRemark: '/v2b/course/schedule/book-remark',
  courseScheduleSummary: '/v2b/course/schedule/summary',
  courseScheduleSetSummary: '/v2b/course/schedule/set-summary',
  courseScheduleAvailableTeacher: '/v2b/course/schedule/available-teacher',
  courseScheduleAvailableRoom: '/v2b/course/schedule/available-room',
  courseScheduleGenerateDate: '/v2b/course/schedule/generate-date',
  courseScheduleXgjCheck: '/v2b/course/schedule/xgj-check',
  courseScheduleCleanCheckin: '/v2b/course/schedule/clean-checkin',
  courseScheduleRemoveMember: '/v2b/course/schedule/remove-member',
  courseScheduleAdjustMember: '/v2b/course/schedule/adjust-member',

  // 教务中心
  scheduleCourseList: '/v2b/schedule/mine/course-list',
  scheduleWait: '/v2b/schedule/mine/wait',
  scheduleLeave: '/v2b/schedule/mine/leave',
  scheduleAttend: '/v2b/schedule/mine/attend',
  scheduleExportBook: '/v2b/schedule/mine/export-book',
  scheduleHomework:'/v2b/schedule/mine/homework',
  scheduleAddHomework:'/v2b/schedule/mine/add-homework-reply',
  scheduleUpdateHomework:'/v2b/schedule/mine/update-homework-reply',
  scheduleDeleteHomework:'/v2b/schedule/mine/delete-homework-reply',
  scheduleHomeworkFinishDetail:'/v2b/schedule/mine/homework-finish-detail',
  scheduleMessages:'/v2b/schedule/mine/messages',
  scheduleClasses: '/v2b/schedule/mine/classes',
  scheduleIndex: '/v2b/schedule/mine/index',
  scheduleHeatmap: '/v2b/schedule/mine/heatmap',
  scheduleTeachStatis: '/v2b/schedule/mine/teach-statis',
  schedulecourseExport: '/v2b/schedule/mine/course-export',
  scheduleLeaveApprove: '/v2b/schedule/mine/leave-approve',
  scheduleComment: '/v2b/schedule/mine/comment',
  scheduleAddComment: '/v2b/schedule/mine/add-comment',
  scheduleAudition: '/v2b/schedule/mine/audition',
  scheduleAuditionExport: '/v2b/schedule/mine/audition-export',
  courseRoom: '/v2b/course/room/index',


  // 排期冲突
  courseConflict: '/v2b/course/conflict/index',
  courseConflictView: '/v2b/course/conflict/view',
  courseConflictEditAjax: '/v2b/course/conflict/edit-ajax',
  courseConflictDelete: '/v2b/course/conflict/delete',
  courseConflictCancel: '/v2b/course/conflict/cancel',
  courseConflictAjaxCancel: '/v2b/course/conflict/ajax-cancel',
  courseConflictSubstitute: '/v2b/course/conflict/substitute',

  // 课件中心
  courseWare: '/v2b/course/ware/index',
  courseWareAdd: '/v2b/course/ware/add',
  courseWareAddCateLevel: '/v2b/course/ware/add-cate-level',
  courseWareUpdateCateLevel: '/v2b/course/ware/update-cate-level',
  courseWareDelCateLevel: '/v2b/course/ware/del-cate-level',
  courseWareAddCateLevelContent: '/v2b/course/ware/add-cate-level-content',
  courseWareDetail: '/v2b/course/ware/detail',
  courseWareUpdate: '/v2b/course/ware/update',
  courseWareDownload: '/v2b/course/ware/download',
  courseWareDelete: '/v2b/course/ware/delete',
  courseWareRecovery: '/v2b/course/ware/recovery',
  courseWareCate: '/v2b/course/ware/cate',
  courseWareCateLevel: '/v2b/course/ware/cate-level',
  courseWareCourseContent: '/v2b/course/ware/course-content',
  courseWareAddCourseContent: '/v2b/course/ware/add-course-content',
  courseWareUpdateCourseContent: '/v2b/course/ware/update-course-content',
  courseWareCateLevelContent: '/v2b/course/ware/cate-level-content',
  courseWareUpdateCateLevelContent: '/v2b/course/ware/update-cate-level-content',
  courseWareDelCateLevelContent: '/v2b/course/ware/del-cate-level-content',
  courseWareCateLevelContentDetail: '/v2b/course/ware/cate-level-content-detail',
  courseWareCateLevelContentComment: '/v2b/course/ware/cate-level-content-comment',
  courseWareCateLevelContentAddComment: '/v2b/course/ware/cate-level-content-add-comment',

  // 学员证书
  courseCertificate: '/v2b/course/certificate/index',
  courseCertificateList: '/v2b/course/certificate/list',
  courseCertificateSendCertificate: '/v2b/course/certificate/send-certificate',
  courseCertificateCancalCertificate: '/v2b/course/certificate/cancel-certificate',
  courseCertificateSendList: '/v2b/course/certificate/send-list',
  courseCertificateAdd: '/v2b/course/certificate/add',
  courseCertificateEdit: '/v2b/course/certificate/update',
  courseCertificateView: '/v2b/course/certificate/view',
  courseCertificateViewCertificate: '/v2b/course/certificate/view-certificate',
  courseCertificateDelete: '/v2b/course/certificate/delete',
  courseCertificateRecovery: '/v2b/course/certificate/recovery',

  // 成就分类
  courseAchievement: '/v2b/course/achievement/index',
  courseAchievementAdd: '/v2b/course/achievement/add',
  courseAchievementUpdate: '/v2b/course/achievement/update',
  courseAchievementDetail: '/v2b/course/achievement/detail',
  courseAchievementRecord: '/v2b/course/achievement/record',
  courseAchievementAddIcon: '/v2b/course/achievement/add-icon',
  courseAchievementViewIcon: '/v2b/course/achievement/view-icon',
  courseAchievementDetailIcon: '/v2b/course/achievement/detail-icon',
  courseAchievementUpdateIcon: '/v2b/course/achievement/update-icon',
  courseAchievementAjaxSortMadel: '/v2b/course/achievement/sort-madel',
  courseAchievementDelete: '/v2b/course/achievement/delete',
  courseAchievementRecovery: '/v2b/course/achievement/recovery',
  courseAchievementDeleteIcon: '/v2b/course/achievement/delete-icon',

  // 备课
  coursePrepare: '/v2b/course/prepare/index',
  coursePrepareMy: '/v2b/course/prepare/my',
  coursePrepareCheck: '/v2b/course/prepare/check',
  coursePrepareAdd: '/v2b/course/prepare/add',
  coursePrepareAnalysis: '/v2b/course/prepare/analysis',
  coursePrepareView: '/v2b/course/prepare/view',
  coursePrepareRespond: '/v2b/course/prepare/respond',
  coursePrepareAjaxSendNotice: '/v2b/course/prepare/ajax-send-notice',
  coursePrepareAjaxIgnore: '/v2b/course/prepare/ajax-ignore',
  coursePrepareSendNotice: '/v2b/course/prepare/send-notice',
  analysisPrepare: '/v2b/analysis/prepare/index',

  // 课程收费
  courseFee: '/v2b/course_fee/fee/index',
  courseFeeDelete: '/v2b/course_fee/fee/delete',
  courseFeeAdd: '/v2b/course_fee/fee/add',
  courseFeeUpdate: '/v2b/course_fee/fee/update',
  courseCoupon: '/v2b/course_fee/coupon/index',
  courseCouponDetail: '/v2b/course_fee/coupon/detail',
  courseCouponAdd: '/v2b/course_fee/coupon/add',
  courseCouponUpdate: '/v2b/course_fee/coupon/update',
  courseCouponDelete: '/v2b/course_fee/coupon/delete',
  courseCouponSendList: '/v2b/course_fee/coupon/send-list',
  courseCouponGrant: '/v2b/course_fee/coupon/grant',
  courseCouponExport: '/v2b/course_fee/coupon/export',
  courseCouponCancelCoupon: '/v2b/course_fee/coupon/cancel-coupon',
  courseCouponSendListGraph: '/v2b/course_fee/coupon/send-list-graph',

  // 渠道二维码
  marketingQrchannel: '/v2b/marketing/qrchannel/index',
  marketingQrchannelAdd: '/v2b/marketing/qrchannel/add',
  marketingQrchannelUpdate: '/v2b/marketing/qrchannel/update',
  marketingQrchannelDownload: '/v2b/marketing/qrchannel/download',

  //租领备案
  marketingParklist: '/v2b/marketing/rent/parklist',
  marketingParkAdd: '/v2b/marketing/rent/park-add',
  marketingParkDetail: '/v2b/marketing/rent/park-detail', 
  marketingRoomDetail: '/v2b/marketing/rent/room-detail', 
  marketingParkUpdate: '/v2b/marketing/rent/park-update', 
  marketingParkBillAdd: '/v2b/marketing/rent/bill-add',
  marketingParkRoomAdd: '/v2b/marketing/rent/room-add',
  marketingParkRoomUpdate: '/v2b/marketing/rent/room-update',
  marketingRentBilllist: '/v2b/marketing/rent/paymentlist',
  marketingRentBillDel: '/v2b/marketing/rent/bill-del',
  marketingRentBillExport: '/v2b/marketing/rent/bill-export',
  marketingRentBillConfirm: '/v2b/marketing/rent/bill-confirm',
  marketingRentBillAnalysis: '/v2b/marketing/rent/analysis',
  marketingRentRoomlist: '/v2b/marketing/rent/roomlist',
  marketingRentRoomRecovery: '/v2b/marketing/rent/room-recovery',
  marketingRentRoomDel: '/v2b/marketing/rent/room-del',
  marketingRentBillFapiao: '/v2b/marketing/rent/bill-fapiao',
  marketingRentFilterRoom: '/v2b/marketing/rent/filter-room',
  marketingRentFilterPark: '/v2b/marketing/rent/filter-park',

  // 收款二维码列表
  marketingQrcodepayment: '/v2b/marketing/qrcodepayment/index',
  marketingQrcodepaymentQrcodeview: '/v2b/marketing/qrcodepayment/qrcodeview',
  marketingQrcodepaymentPaymentlist: '/v2b/marketing/qrcodepayment/paymentlist',
  marketingQrcodepaymentExportPaymentlist: '/v2b/marketing/qrcodepayment/export-paymentlist',
  marketingQrcodepaymentSetting: '/v2b/marketing/qrcodepayment/setting',
  marketingQrcodepaymentAdd: '/v2b/marketing/qrcodepayment/add',
  marketingQrcodepaymentDownload: '/v2b/marketing/qrcodepayment/download',
  marketingQrcodepaymentDeleteNotify: '/v2b/marketing/qrcodepayment/delete-notify',
  marketingQrcodepaymentDelete: '/v2b/marketing/qrcodepayment/delete',
  marketingQrcodepaymentRecovery: '/v2b/marketing/qrcodepayment/recovery',

  // 知识付费
  knowledgePackage: '/v2b/knowledge/package/index',
  knowledgePackageAdd: '/v2b/knowledge/package/add',
  knowledgePackageDetail: '/v2b/knowledge/package/detail',
  knowledgePackageUpdate: '/v2b/knowledge/package/update',
  knowledgePackageDelete: '/v2b/knowledge/package/delete',
  knowledgePackageRecovery: '/v2b/knowledge/package/recovery',
  knowledgePackageContent: '/v2b/knowledge/package/content',
  knowledgePackageMember: '/v2b/knowledge/package/member',
  knowledgeContent: '/v2b/knowledge/content/index',
  knowledgeContentDetail: '/v2b/knowledge/content/detail',
  knowledgeContentAdd: '/v2b/knowledge/content/add',
  knowledgePackageCanRelatedContent: '/v2b/knowledge/package/can-related-content',
  knowledgeContentRelatedTo: '/v2b/knowledge/package/related-to',
  knowledgeContentCancelRelated: '/v2b/knowledge/package/cancel-related',
  knowledgeContentChangeOrder: '/v2b/knowledge/package/change-order',
  knowledgeContentUpdate: '/v2b/knowledge/content/update',
  knowledgeContentDelete: '/v2b/knowledge/content/delete',
  knowledgeContentRecovery: '/v2b/knowledge/content/recovery',
  knowledgeContentComment: '/v2b/knowledge/content/comment',
  knowledgeContentCommentComfirm: '/v2b/knowledge/content/comment-comfirm',
  knowledgeContentCommentRefuse: '/v2b/knowledge/content/comment-refuse',

  // 请假记录
  absenceCancel: '/v2b/absence/cancel/index',
  absenceCancelExport: '/v2b/absence/cancel/export',
  absenceApply: '/v2b/absence/apply/index',
  absenceApplyConfirm: '/v2b/absence/apply/confirm',
  absenceApplyRefuse: '/v2b/absence/apply/refuse',

  // 上课记录
  absenceSignin: '/v2b/absence/signin/index',
  // 学员请假
  absenceSigninCancel: '/v2b/absence/signin/cancel',
  // 学员签到
  absenceSigninCheckin: '/v2b/absence/signin/checkin',
  absenceSigninAttend: '/v2b/absence/signin/attend',
  absenceSigninExport: '/v2b/absence/signin/export',

  // 在线招募
  weipagesRecruit: '/v2b/weipages/recruit/index',
  weipagesRecruitAdd: '/v2b/weipages/recruit/add',
  weipagesRecruitDetail: '/v2b/weipages/recruit/detail',
  weipagesRecruitUpdate: '/v2b/weipages/recruit/update',
  weipagesRecruitDelete: '/v2b/weipages/recruit/delete',
  weipagesRecruitRecovery: '/v2b/weipages/recruit/recovery',
  weipagesRecruitMember: '/v2b/weipages/recruit/member',
  weipagesRecruitDeleteMember: '/v2b/weipages/recruit/delete-member',
  weipagesRecruitExport: '/v2b/weipages/recruit/export',

  // CRM
  // 业绩统计
  achievement: '/v2b/setting/crm/achievement',
  studioAchievement: '/v2b/setting/crm/studio-achievement',
  studioAchievementExport: '/v2b/setting/crm/studio-achievement-export',
  achievementUpdate: '/v2b/setting/crm/achievement-update',
  studioAchievementUpdate: '/v2b/setting/crm/studio-achievement-update',
  achievementExport: '/v2b/setting/crm/achievement-export',
  // 招生咨询规则
  settingCrmConfig: '/v2b/setting/crm/config',
  settingCrmConfigUpdate: '/v2b/setting/crm/config-update',
  settingCrmCustomerConfig: '/v2b/setting/crm/customer-config',
  settingCrmCustomerConfigAdd: '/v2b/setting/crm/customer-config-add',
  settingCrmCustomerConfigUpdate: '/v2b/setting/crm/customer-config-update',
  settingCrmCustomerConfigDelete: '/v2b/setting/crm/customer-config-delete',
  // 用户渠道分析
  achievementChannel: '/v2b/crm/achievement/channel',
  
  // 预约试听
  audition: '/v2b/crm/audition/index',
  auditionBook: '/v2b/crm/audition/book',
  // 预约试听
  auditionDemoSchedule: '/v2b/crm/audition/demo-schedule',
  auditionUpdate: '/v2b/crm/audition/update',
  auditionAdd: '/v2b/crm/audition/add',
  auditionPendding: '/v2b/crm/audition/pendding',
  auditionDetail: '/v2b/crm/audition/detail',
  auditionCancel: '/v2b/crm/audition/cancel',
  auditionSign: '/v2b/crm/audition/sign',
  auditionDelete: '/v2b/crm/audition/delete',
  auditionCancelDemoClass: '/v2b/crm/audition/cancel-demo-class',
  auditionDemoTime: '/v2b/crm/audition/demo-time',
  auditionResend: '/v2b/crm/audition/resend',
  auditionAddDemo: '/v2b/crm/audition/add-demo',
  auditionAddDemoSchedule: '/v2b/crm/audition/add-demo-schedule',
  auditionDemoArrage: '/v2b/crm/audition/demo-arrage',
  auditionSignin: '/v2b/crm/audition/signin',
  auditionNoShow: '/v2b/crm/audition/no-show',
  auditionSchedule: '/v2b/crm/audition/schedule',
  auditionArrange: '/v2b/crm/audition/arrange',

  // 招生池
  client: '/v2b/crm/client/index',
  clientAssign: '/v2b/crm/client/assign',
  clientPendding: '/v2b/crm/client/pendding',
  clientChangeLog: '/v2b/crm/client/change-log',
  clientFollowLog: '/v2b/crm/client/follow-log',
  clientStar: '/v2b/crm/client/star',
  clientAdd: '/v2b/crm/client/add',
  clientUpdate: '/v2b/crm/client/update',
  clientDetail: '/v2b/crm/client/detail',
  clientFollow: '/v2b/crm/client/follow',
  clientNoneWill: '/v2b/crm/client/none-will',
  clientToday: '/v2b/crm/client/today',
  clientMultiSetting: '/v2b/crm/client/multi-setting',
  clientMultiExport: '/v2b/crm/client/multi-export',
  clientMultiAction: '/v2b/crm/client/multi-action',
  clientDelete: '/v2b/crm/client/delete',
  crmBrief: '/v2b/crm/brief/index',

  // 待办事项
  todoToday: '/v2b/crm/todo/today',
  todoTodayLeads: '/v2b/crm/todo/today-leads',
  todoCount: '/v2b/crm/todo/count',
  todoLeads: '/v2b/crm/todo/leads',
  todoCustomer: '/v2b/crm/todo/customer',
  todoContract: '/v2b/crm/todo/contract',
  todoRemindCustomer: '/v2b/crm/todo/remind-customer',

  // 招生线索
  cLeads: '/v2b/crm/leads/index',
  cLeadsBoard: '/v2b/crm/leads/board',
  cLeadsTransfer: '/v2b/crm/leads/transfer',
  cLeadsTransform: '/v2b/crm/leads/transform',
  cLeadsChangeLog: '/v2b/crm/leads/change-log',
  cLeadsFollowLog: '/v2b/crm/leads/follow-log',
  cLeadsFollow: '/v2b/crm/leads/follow',
  cLeadsAdd: '/v2b/crm/leads/add',
  cLeadsUpdate: '/v2b/crm/leads/update',
  cLeadsUpload: '/v2b/crm/leads/upload',
  cLeadsDetail: '/v2b/crm/leads/detail',
  cLeadsDelete: '/v2b/crm/leads/delete',
  cLeadsExample: '/v2b/crm/leads/example',
  cLeadsExport: '/v2b/crm/leads/export',
  cLeadsErrorFile: '/v2b/crm/leads/error-file',
  cLeadsTaskUpdate: '/v2b/crm/leads/task-update',
  cLeadsRenew: '/v2b/crm/leads/renew',
  cLeadsStar: '/v2b/crm/leads/star',
  
  // 意向客户导入
  cImport: '/v2b/crm/import/index',
  cImportUpload: '/v2b/crm/import/upload',
  cImportDelete: '/v2b/crm/import/delete',

  // 仪表盘
  cWorkbench: '/v2b/crm/workbench/index',
  cWorkbenchAchievement: '/v2b/crm/workbench/achievement',
  cWorkbenchFunnel: '/v2b/crm/workbench/funnel',
  cWorkbenchSale: '/v2b/crm/workbench/sale-statis',
  cWorkbenchHeatmap: '/v2b/crm/workbench/heatmap ',

  // 招生客户
  cCustomer: '/v2b/crm/customer/index',
  cCustomerLost: '/v2b/crm/customer/lost',
  cCustomerFollow: '/v2b/crm/customer/follow',
  cCustomerFollowLog: '/v2b/crm/customer/follow-log',
  cCustomerChangeLog: '/v2b/crm/customer/change-log',
  cCustomerDetail: '/v2b/crm/customer/detail',
  cCustomerRefresh: '/v2b/crm/customer/refresh',
  cCustomerUpdate: '/v2b/crm/customer/update',
  cCustomerUpload: '/v2b/crm/customer/upload',
  cCustomerExample: '/v2b/crm/customer/example',
  cCustomerErrorFile: '/v2b/crm/customer/error-file',
  cCustomerReceive: '/v2b/crm/customer/receive',
  cCustomerTransfer: '/v2b/crm/customer/transfer',
  cCustomerDelete: '/v2b/crm/customer/delete',
  cCustomerContract: '/v2b/crm/customer/contract',
  cCustomerAdd: '/v2b/crm/customer/add',
  cCustomerExport: '/v2b/crm/customer/export',
  cCustomerTaskUpdate: '/v2b/crm/customer/task-update',
  cCustomerStudentAccount: '/v2b/crm/customer/student-account',
  cCustomerPutinPool: '/v2b/crm/customer/putin-pool',
  cCustomerStar: '/v2b/crm/customer/star',
  cCustomerRenew: '/v2b/crm/customer/renew',
  cCustomerWxworkContact: '/v2b/crm/customer/wxwork-contact',
  cCustomerWxworkMsg: '/v2b/crm/customer/wxwork-msg',
  cCustomerStartEvent: '/v2b/crm/customer/start-event',

  // 联系人
  cCustomerContact: '/v2b/crm/customer/contact',
  cCustomerAddContact: '/v2b/crm/customer/add-contact',
  cCustomerContactDetail: '/v2b/crm/customer/contact-detail',
  cCustomerUpdateContact: '/v2b/crm/customer/update-contact',

  //试听记录
  cCustomerAudition: '/v2b/crm/customer/audition',
  

  // 客户公海
  cCustomerPool: '/v2b/crm/customer/pool',
  cCustomerPoolReceive: '/v2b/crm/customer/pool-receive',
  cCustomerPoolTransfer: '/v2b/crm/customer/pool-transfer',
  cCustomerPoolExport: '/v2b/crm/customer/pool-export',

  //我的合同
  cContract: '/v2b/crm/contract/index',
  cContractReminder: '/v2b/crm/contract/reminder',
  cContractReminderExport: '/v2b/crm/contract/reminder-export',
  cContractExport: '/v2b/crm/contract/export',

  // 邀约到访
  cInviteAttend: '/v2b/crm/invite-attend/index',
  cInviteAttendAttend: '/v2b/crm/invite-attend/attend',
  cInviteAttendNoShow: '/v2b/crm/invite-attend/no-show',
  cInviteAttendExport: '/v2b/crm/invite-attend/export',


  // 回访记录
  cFollow: '/v2b/crm/follow/index',
  cFollowExport: '/v2b/crm/follow/export',
  cFollowHeatmap: '/v2b/crm/follow/heatmap',
  cFollowDetail: '/v2b/crm/follow/detail',
  cFollowDelete: '/v2b/crm/follow/delete',
  cFollowEvent: '/v2b/crm/follow/event',
  cFollowEventAdd: '/v2b/crm/follow/event-add',

  // 今日回访
  cTodo: '/v2b/crm/todo/index',

  // 转移记录
  cTransfer: '/v2b/crm/transfer/index',
  cTransferExport: '/v2b/crm/transfer/export',

  // 变更记录
  cChangeLog: '/v2b/crm/changelog/index',
  cChangeLogExport: '/v2b/crm/changelog/export',

  // 招生渠道列表
  cChannel: '/v2b/crm/channel/index',
  cChannelAdd: '/v2b/crm/channel/add',
  cChannelExport: '/v2b/crm/channel/export',
  cChannelUpdate: '/v2b/crm/channel/update',
  cChannelMoveTo: '/v2b/crm/channel/move-to',
  cChannelDetail: '/v2b/crm/channel/detail',
  cChannelDelete: '/v2b/crm/channel/delete',
  cChannelCount: '/v2b/crm/channel/count',
  cChannelLevel: '/v2b/crm/channel/level',

  // 财务中心
  // 支付查询
  paymentEnquiry: '/v2b/payment/receive/enquiry',
  paymentInquiry: '/v2b/payment/receive/inquiry',
  paymentReceive: '/v2b/payment/receive/index',
  paymentReceiveQuery: '/v2b/payment/receive/query',
  paymentReceiveExport: '/v2b/payment/receive/export',
  paymentRefund: '/v2b/payment/refund/index',
  paymentRefundApply: '/v2b/payment/refund/apply',
  paymentRefundQuery: '/v2b/payment/refund/query',

  // 活动管理
  meetingCampaign: '/v2b/meeting/campaign/index',
  meetingCampaignDetail: '/v2b/meeting/campaign/detail',
  meetingCampaignUpdate: '/v2b/meeting/campaign/update',
  meetingCampaignAdd: '/v2b/meeting/campaign/add',
  meetingCampaignSetting: '/v2b/meeting/campaign/setting',
  meetingCampaignQrcodeview: '/v2b/meeting/campaign/qrcodeview',
  meetingCampaignDeleteTicket: '/v2b/meeting/campaign/delete-ticket',
  meetingCampaignRecoveryTicket: '/v2b/meeting/campaign/recovery-ticket',
  meetingCampaignCheckin: '/v2b/meeting/campaign/checkin',
  meetingCampaignUnCheckin: '/v2b/meeting/campaign/un-checkin',
  meetingCampaignCheckAll: '/v2b/meeting/campaign/check-all',
  meetingCampaignAjaxCheckin: '/v2b/meeting/campaign/ajax-checkin',
  meetingCampaignQrcodedownload: '/v2b/meeting/campaign/qrcodedownload',
  meetingCampaignDelete: '/v2b/meeting/campaign/delete',
  meetingCampaignRecovery: '/v2b/meeting/campaign/recovery',
  meetingCampaignDownloadExportList: '/v2b/meeting/campaign/download-export-list',
  meetingCampaignDeleteNotify: '/v2b/meeting/campaign/delete-notify',
  
  // 活动代理商
  meetingChannel: '/v2b/meeting/channel/index',
  meetingChannelAdd: '/v2b/meeting/channel/add',
  meetingChannelUpdate: '/v2b/meeting/channel/update',
  meetingChannelDetail: '/v2b/meeting/channel/detail',

  // 活动媒体
  meetingTicketsDetail: '/v2b/meeting/tickets/detail',
  meetingTicketsFreeType: '/v2b/meeting/tickets/free-type',
  meetingTicketsAddFreeType: '/v2b/meeting/tickets/add-free-type',
  meetingTicketsDiscountType: '/v2b/meeting/tickets/discount-type',
  meetingTicketsAddDiscountType: '/v2b/meeting/tickets/add-discount-type',
  meetingTicketsUpdateType: '/v2b/meeting/tickets/update-type',

  // 活动报名
  meetingTickets: '/v2b/meeting/tickets/index',
  meetingTicketsAdd: '/v2b/meeting/tickets/add',
  meetingTicketsCheckin: '/v2b/meeting/tickets/checkin',
  meetingTicketsUnCheckin: '/v2b/meeting/tickets/un-checkin',
  
  // 活动签到
  meetingCheckin: '/v2b/meeting/checkin/index',
  meetingCheckinQrcodeview: '/v2b/meeting/checkin/qrcodeview',
  
  // 签约合同
  // 签约合同退费操作
  contractRefund: '/v2b/contract/refund/apply',
  // 签约合同
  contractStudents: '/v2b/contract/students/index',
  // 未排班学员
  contractPendding: '/v2b/contract/students/pendding',
  contractExport: '/v2b/contract/students/export',
  // 
  contractPdf: '/v2b/contract/order/pdf',
  contractRollback: '/v2b/contract/order/rollback',
  contractDetail: '/v2b/contract/order/detail',
  financeCosttimes: '/v2b/finance/cost-times/index',
  financeCosttimesCourse: '/v2b/finance/cost-times/course',
  financeCosttimesExport: '/v2b/finance/cost-times/export',

  // 退费办理
  financeRefund: '/v2b/payment/refund/index',
  paymentRefundExport: '/v2b/payment/refund/export',
  financeRefundList: '/v2b/contract/refund/index',
  financeRefundExport: '/v2b/contract/refund/export',
  financeRefundView: '/v2b/payment/refund/view',
  financeRefundApply: '/v2b/payment/refund/apply',

  // 发票申请
  // 发票申请列表
  financeInvoice: '/v2b/finance/invoice/index',
  financeInvoiceUpdate: '/v2b/finance/invoice/update',
  financeInvoiceCheck: '/v2b/finance/invoice/check',
  financeInvoicePullback: '/v2b/finance/invoice/pullback',
  financeInvoiceMultiAction: '/v2b/finance/invoice/multi-action',
  financeInvoiceSwitch: '/v2b/finance/invoice/switch',
  financeInvoiceDetail: '/v2b/finance/invoice/detail',
  financeInvoiceRollback: '/v2b/finance/invoice/rollback',
  financeStatistics: '/v2b/finance/statistics/index',

  // 工资结算
  financeSalaryCalculationRules: '/v2b/finance/salary-calculation/rules',
  financeSalaryCalculationAddRules: '/v2b/finance/salary-calculation/add-rules',
  financeSalaryCalculationUpdateRules: '/v2b/finance/salary-calculation/update-rules',
  financeSalaryCalculationDeleteRules: '/v2b/finance/salary-calculation/delete-rules',
  financeSalaryCalculationTeacher: '/v2b/finance/salary-calculation/teacher',
  financeSalaryCalculationCalculation: '/v2b/finance/salary-calculation/calculation',
  financeSalaryCalculationCommision: '/v2b/finance/salary-calculation/commision',
  financeSalaryCalculationSetCalculation: '/v2b/finance/salary-calculation/set-calculation',
  financeSalaryCalculationRe: '/v2b/finance/salary-calculation/re-calculation-commision',
  financeSalaryCalculationCalculationExport: '/v2b/finance/salary-calculation/calculation-export',
  financeSalaryCalculationExportCommision: '/v2b/finance/salary-calculation/export-commision',
  financeSalary: '/v2b/finance/salary/index',
  financeSalaryColumns: '/v2b/finance/salary/columns',
  financeSalaryAddColumns: '/v2b/finance/salary/add-column',
  financeSalaryUPdateColumns: '/v2b/finance/salary/update-column',
  financeSalaryDeleteColumns: '/v2b/finance/salary/delete-column',
  financeSalaryUpdateOrder: '/v2b/finance/salary/update-order',
  financeSalaryBill: '/v2b/finance/salary/bill',
  financeSalaryUpdate: '/v2b/finance/salary/update',
  financeSalaryDeleteBill: '/v2b/finance/salary/delete-bill',
  financeSalaryDelete: '/v2b/finance/salary/delete',
  financeSalaryConfirm: '/v2b/finance/salary/confirm',
  financeSalaryExport: '/v2b/finance/salary/export',
  financeSalaryImport: '/v2b/finance/salary/import',
  financeSalaryExample: '/v2b/finance/salary/example',

  // 微信设置
  weixinAuthorization: '/v2b/weixin/auth/index',
  weixinSeting: '/v2b/weixin/setting/index',
  weixinSetingMaterial: '/v2b/weixin/seting/material',
  weixinSetingRefreshMaterial: '/v2b/weixin/seting/refresh-material',
  weixinPayment: '/v2b/weixin/payment/index',
  weixinPaymentUpdate: '/v2b/weixin/payment/update',
  weixinTemplate: '/v2b/weixin/template/index',
  weixinTemplateColor: '/v2b/weixin/template/color',
  weixinTemplateRefresh: '/v2b/weixin/template/refresh',

  // 消息存档
  wxworkMsg: '/v2b/wxwork/msg/index',
  wxworkMsgStaff: '/v2b/wxwork/msg/staff',
  wxworkMsgDetail: '/v2b/wxwork/msg/detail',
  wxworkMsgDetailExport: '/v2b/wxwork/msg/detail-export',
  wxworkMsgSearch: '/v2b/wxwork/msg/search',
  wxworkMsgInvalid: '/v2b/wxwork/msg/invalid',
  wxworkMsgChats: '/v2b/wxwork/msg/chats',
  wxworkMsgAnalysis: '/v2b/wxwork/msg/analysis',
  wxworkMsgAnalysisExport: '/v2b/wxwork/msg/analysis-export',
  wxworkMsgHeatmap: '/v2b/wxwork/msg/heatmap',
  wxworkMsgContact: '/v2b/wxwork/msg/contact',
  wxworkMsgContactHistory: '/v2b/wxwork/msg/contact-history',
  wxworkMsgUnfollowHistory: '/v2b/wxwork/msg/unfollow-history',
  wxworkMsgGroupMember: '/v2b/wxwork/msg/group-member',
  wxworkMsgTag: '/v2b/wxwork/msg/tag',
  wxworkMsgRoom: '/v2b/wxwork/msg/room',
  wxworkExternalContactDetil: '/v2b/wxwork/external-contact/detail',
  wxworkStaffIndex: '/v2b/wxwork/staff/index',
  wxworkStaffDepart: '/v2b/wxwork/staff/depart',

  // 设置
  settingSystem: '/v2b/setting/system/index',
  settingSystemSms: '/v2b/setting/system/sms',
  settingSystemSmsPay: '/v2b/setting/system/sms-pay',
  settingSystemSmsAnalysis: '/v2b/setting/system/sms-analysis',
  settingSystemLongpoll: '/v2b/setting/system/longpoll',


  settingSystemMonitor: '/v2b/setting/monitor/config',
  settingSystemMonitorUpdate: '/v2b/setting/monitor/config-update',

  settingSystemEducation: '/v2b/setting/education/config',
  settingSystemEducationUpdate: '/v2b/setting/education/config-update',
  settingSystemEducationAchievement: '/v2b/setting/education/studio-achievement',
  settingSystemEducationAchievementUpdate: '/v2b/setting/education/studio-achievement-update',
  settingSystemEducationAppointment: '/v2b/setting/education/appointment',
  settingSystemEducationAddAppointment: '/v2b/setting/education/add-appointment',
  settingSystemEducationDeleteAppointment: '/v2b/setting/education/delete-appointment',
  settingSystemAudition: '/v2b/setting/system/audition',
  settingStudioNotice: '/v2b/setting/studio-notice/index',
  settingStudioNoticeAdd: '/v2b/setting/studio-notice/add-notice',
  settingStudioNoticeUpdate: '/v2b/setting/studio-notice/update-notice',
  settingStudioNoticeDelete: '/v2b/setting/studio-notice/delete',
  settingStudioNoticeAccount: '/v2b/setting/studio-notice/account',
  settingScanCheckin: '/v2b/setting/scan-checkin/index',
  settingScanCheckinAdd: '/v2b/setting/scan-checkin/add-notice',
  settingScanCheckinDelete: '/v2b/setting/scan-checkin/delete',
  settingPaymentNotice: '/v2b/setting/payment-notice/index',
  settingPaymentNoticeAdd: '/v2b/setting/payment-notice/add-notice',
  settingPaymentNoticeDelete: '/v2b/setting/payment-notice/delete',
  settingPaymentNoticeAccount: '/v2b/setting/payment-notice/account',
  settingAliPayment: '/v2b/setting/ali-payment/index',
  settingAliPaymentUpdate: '/v2b/setting/ali-payment/update',
  settingAgreement: '/v2b/setting/agreement/index',
  settingBanner: '/v2b/setting/banner/index',
  settingBannerAdd: '/v2b/setting/banner/add',
  settingBannerUpdate: '/v2b/setting/banner/update',
  settingBannerDetail: '/v2b/setting/banner/detail',
  settingBannerDelete: '/v2b/setting/banner/delete',
  settingBannerRecovery: '/v2b/setting/banner/recovery',
  settingWxworkInstall: '/v2b/wxwork/install/index',
  settingWxworkCallback: '/v2b/wxwork/install/callback',
  settingWxwork: '/v2b/setting/wxwork/msg-setting-index',
  settingWxworkUpdate: '/v2b/setting/wxwork/msg-setting-update',
  settingWxworkInvalidKeywords: '/v2b/setting/wxwork/invalid-keywords',
  settingWxworkSetKeywords: '/v2b/setting/wxwork/set-invalid-keywords',
  settingWxworkAddTags: '/v2b/setting/wxwork/add-tags',
  settingWxworkDelTags: '/v2b/setting/wxwork/del-tags',
  settingWxworkTagGroup: '/v2b/setting/wxwork/tag-group',
  settingWxworkTagSync: '/v2b/setting/wxwork/tag-sync',
  settingCreditUpdate: '/v2b/credit/setting/update',
  settingCreditIndex: '/v2b/credit/setting/index',
  settingfinanceConfig: '/v2b/setting/finance/config',
  settingfinanceConfigUpdate: '/v2b/setting/finance/config-update',
  settingfinanceFiscal: '/v2b/setting/finance/fiscal',
  settingfinanceSetFiscal: '/v2b/setting/finance/set-fiscal',
  settingfinanceLockFiscal: '/v2b/setting/finance/lock-fiscal',
  settingfinanceUnlockFiscal: '/v2b/setting/finance/unlock-fiscal',

  // 操作记录列表
  loggingIndex: '/v2b/logging/logging/index',
  loggingExport: '/v2b/logging/logging/export',

  // 账户
  employeeAccount: '/v2b/employee/account/index',
  employeeAccountAdd: '/v2b/employee/account/add',

  employeeAccountFace: '/v2b/employee/account/face',
  employeeAccountSetFace: '/v2b/employee/account/set-face',
  employeeAccountCleanFace: '/v2b/employee/account/clean-face',

  employeeAccountDetail: '/v2b/employee/account/detail',
  employeeAccountUpdate: '/v2b/employee/account/update',
  employeeAccountRoles: '/v2b/employee/account/roles',
  employeeAccountRecovery: '/v2b/employee/account/recovery',
  employeeAccountDelete: '/v2b/employee/account/delete',
  employeeAccountResetPassword: '/v2b/employee/account/reset-password',
  employeeAccountQrcode: '/v2b/employee/account/qrcode',
  employeeAccountMoveTo: '/v2b/employee/account/move-to',
  employeeAccountTreeGraph: '/v2b/employee/account/tree-graph',
  employeeApply: '/v2b/employee/apply/index',
  employeeApplyConfirm: '/v2b/employee/apply/confirm-apply',
  employeeApplyDelelte: '/v2b/employee/apply/delete-apply',
  employeeOrganization: '/v2b/employee/department/index',
  employeeOrganizationSave: '/v2b/employee/department/save-department',
  employeeOrganizationUpdate: '/v2b/employee/department/update-department',
  employeeOrganizationDel: '/v2b/employee/department/del-department',
  employeeOrganizationMove: '/v2b/employee/department/move-to',
  employeeOrganizationTreeGraph: '/v2b/employee/department/tree-graph',

  // 角色管理
  employeeRole: '/v2b/employee/role/index',
  employeeRolePermission: '/v2b/employee/role/permission',
  employeeRoleAdd: '/v2b/employee/role/add',
  employeeRoleUpdate: '/v2b/employee/role/update',
  employeeRoleAssign: '/v2b/employee/role/assign',
  employeeRoleRemove: '/v2b/employee/role/remove',

  employeeGroup: '/v2b/employee/group/index',
  employeeGroupAdd: '/v2b/employee/group/add',
  employeeRule: '/v2b/employee/rule/index',
  employeeGroupUpdate: '/v2b/employee/group/update',
  employeeGroupDelete: '/v2b/employee/group/delete',
  employeeGroupRemove: '/v2b/employee/group/remove',
  employeeGroupRelatedTo: '/v2b/employee/group/related-to',
  employeeGroupType: '/v2b/employee/group/type',

  employeePosition: '/v2b/employee/position/index',
  employeePositionUpdate: '/v2b/employee/position/update',
  employeePositionAdd: '/v2b/employee/position/add',
  employeePositionDelete: '/v2b/employee/position/delete',


  // 仓库管理
  stockWarehouse: '/v2b/stock/warehouse/index',
  stockWarehouseAdd: '/v2b/stock/warehouse/add',
  stockWarehouseDetail: '/v2b/stock/warehouse/detail',
  stockWarehouseUpdate: '/v2b/stock/warehouse/update',
  stockWarehouseDelete: '/v2b/stock/warehouse/delete',
  stockWarehouseRecovery: '/v2b/stock/warehouse/recovery',
  stockProduct: '/v2b/stock/product/index',
  stockProductAdd: '/v2b/stock/product/add',
  stockProductUpdate: '/v2b/stock/product/update',
  stockProductDetail: '/v2b/stock/product/detail',
  stockProductStock: '/v2b/stock/product/stock',
  stockProductRecords: '/v2b/stock/product/records',
  stockProductIncome: '/v2b/stock/product/income',
  stockProductMinus: '/v2b/stock/product/minus',

  // 校区教室
  roomIndex: '/v2b/room/room/index',
  roomDetail: '/v2b/room/room/detail',
  roomUpdate: '/v2b/room/room/update',
  roomAdd: '/v2b/room/room/add',
  roomDelete: '/v2b/room/room/delete',
  roomRecovery: '/v2b/room/room/recovery',

  // 控制面板
  dashboardIndex: '/v2b/dashboard/index/index',
  dashboardIndexCrm: '/v2b/dashboard/index/crm',
  dashboardIndexEducation: '/v2b/dashboard/index/education',
  dashboardIndexFinance: '/v2b/dashboard/index/finance',
  dashboardTeacher: '/v2b/dashboard/index/teacher',
  dashboardCc: '/v2b/dashboard/index/cc',

  // 数据统计

  analysisBoard: '/v2b/analysis/board/index',
  analysisMainpanel: '/v2b/analysis/mainpanel/index',
  analysisMainpanelCrm: '/v2b/analysis/mainpanel/crm-chart',
  analysisMainpanelEdu: '/v2b/analysis/mainpanel/edu-chart',
  analysisClients: '/v2b/analysis/clients/index',
  analysisClientsChannel: '/v2b/analysis/clients/channel',
  analysisClientsTag: '/v2b/analysis/clients/tag',
  analysisClientsDeal: '/v2b/analysis/clients/deal',
  analysisClientsFollowUp: '/v2b/analysis/clients/follow-up',
  analysisRefund: '/v2b/analysis/refund/index',
  analysisKnowledge: '/v2b/analysis/knowledge/index',
  analysisCampus: '/v2b/analysis/campus/index',
  analysisCampusCount: '/v2b/analysis/campus/count',
  analysisCampusRank: '/v2b/analysis/campus/rank',

  
  analysisCampusDebt: '/v2b/analysis/campus/debt',

  analysisCampusCommision: '/v2b/analysis/campus/commision',
  analysisCampusAchievement: '/v2b/analysis/campus/achievement',
  analysisCampusAchievementList: '/v2b/analysis/campus/achievement-list',
  analysisCampusTaskHeatmap: '/v2b/analysis/campus/task-heatmap',
  analysisCampusCourseHeatmap: '/v2b/analysis/campus/course-heatmap',
  analysisCampusFollowHeatmap: '/v2b/analysis/campus/follow-heatmap',
  analysisCampusRevenueHeatmap: '/v2b/analysis/campus/revenue-heatmap',
  analysisCampusRevenueTotalHeatmap: '/v2b/analysis/campus/revenue-total-heatmap',
  analysisCampusContractHeatmap: '/v2b/analysis/campus/contract-heatmap',
  analysisCampusContractTotalHeatmap: '/v2b/analysis/campus/contract-total-heatmap',
  analysisCampusBookHeatmap: '/v2b/analysis/campus/book-heatmap',
  analysisCampusRefundHeatmap: '/v2b/analysis/campus/refund-heatmap',
  analysisCampusInvitedHeatmap: '/v2b/analysis/campus/invited-heatmap',
  
  analysisRoom: '/v2b/analysis/room/index',
  analysisRoomSummate: '/v2b/analysis/room/summate',
  analysisRoomBook: '/v2b/analysis/room/book',
  analysisRoomCount: '/v2b/analysis/room/count',
  analysisStudio: '/v2b/analysis/studio/index',
  analysisAchievement: '/v2b/analysis/achievement/index',
  analysisClasses: '/v2b/analysis/course/classes',
  analysisStudents: '/v2b/analysis/students/index',
  analysisStudentsCount: '/v2b/analysis/students/count',
  analysisStudentsGender: '/v2b/analysis/students/gender',
  analysisStudentsAge: '/v2b/analysis/students/age',
  analysisStudentsSummate: '/v2b/analysis/students/summate',
  analysisStudentsFee:'/v2b/analysis/students/fee',
  analysisCourseBalance: '/v2b/analysis/course-balance/index',
  analysisCourseBalanceCosts: '/v2b/analysis/course-balance/costs',
  analysisCourseBalanceCount: '/v2b/analysis/course-balance/count',
  analysisCourseBalanceStudio: '/v2b/analysis/course-balance/studio',
  analysisCourseBalanceStudioCosts: '/v2b/analysis/course-balance/studio-costs',
  analysisCourseBalanceAmount: '/v2b/analysis/course-balance/amount',
  analysisCourseBalanceRevenue: '/v2b/analysis/course-balance/revenue',
  analysisCourseBalanceExportStudio: '/v2b/analysis/course-balance/export-studio',
  analysisCourseBalanceExportEmployee: '/v2b/analysis/course-balance/export-employee',
  analysisCourseBalanceRevenueList: '/v2b/analysis/course-balance/revenue-list',
  analysisCourseBalanceRevenueListExport: '/v2b/analysis/course-balance/revenue-list-export',
  analysisCredits: '/v2b/analysis/credits/index',
  analysisCreditsCosts: '/v2b/analysis/credits/costs',
  analysisCreditsCount: '/v2b/analysis/credits/count',


  //招生咨询统计
  analysisCrmLeads: '/v2b/analysis/crm/leads/index',
  analysisCrmLeadsAmount: '/v2b/analysis/crm/leads/amount',
  analysisCrmLeadsStock: '/v2b/analysis/crm/leads/stock',
  analysisCrmLeadsChannel: '/v2b/analysis/crm/leads/channel',
  analysisCrmLeadsSubChannel: '/v2b/analysis/crm/leads/sub-channel',
  analysisCrmLeadsTransfer: '/v2b/analysis/crm/leads/transfer',
  analysisCrmLeadsSankey: '/v2b/analysis/crm/leads/sankey',
  analysisCrmLeadsChannelStock: '/v2b/analysis/crm/leads/channel-stock',
  analysisCrmLeadsChannelStockExport: '/v2b/analysis/crm/leads/channel-stock-export',
  analysisCrmLeadsEmployee: '/v2b/analysis/crm/leads/employee',
  analysisCrmLeadsEmployeeExport: '/v2b/analysis/crm/leads/employee-export',
  analysisCrmLeadsEmployeeLeadsDetail: '/v2b/analysis/crm/leads/employee-leads-detail',
  analysisCrmLeadsStudio: '/v2b/analysis/crm/leads/studio',
  analysisCrmLeadsStudioExport: '/v2b/analysis/crm/leads/studio-export',
  analysisCrmLeadsStudioLeadsDetail: '/v2b/analysis/crm/leads/studio-leads-detail',
  analysisCrmCustomer: '/v2b/analysis/crm/customer/index',
  analysisCrmCustomerChannel: '/v2b/analysis/crm/customer/channel',
  analysisCrmCustomerChannelStock: '/v2b/analysis/crm/customer/channel-stock',
  analysisCrmCustomerChannelStockExport: '/v2b/analysis/crm/customer/channel-stock-export',
  analysisCrmCustomerSubChannel: '/v2b/analysis/crm/customer/sub-channel',
  analysisCrmCustomerCount: '/v2b/analysis/crm/customer/count',
  analysisCrmCustomerAchievement: '/v2b/analysis/crm/customer/achievement',
  analysisCrmCustomerAchievementChannel: '/v2b/analysis/crm/customer/achievement-channel',
  analysisCrmCustomerContractCycle: '/v2b/analysis/crm/customer/contract-cycle',

  analysisCrmCustomerCycle: '/v2b/analysis/crm/customer/cycle',
  analysisCrmCustomerPool: '/v2b/analysis/crm/customer/pool',
  analysisCrmCustomerSankey: '/v2b/analysis/crm/customer/sankey',
  analysisCrmCustomerAmount: '/v2b/analysis/crm/customer/amount',
  analysisCrmCustomerEmployee: '/v2b/analysis/crm/customer/employee',
  analysisCrmCustomerEmployeeCustomerDetail: '/v2b/analysis/crm/customer/employee-customer-detail',
  analysisCrmCustomerStudio: '/v2b/analysis/crm/customer/studio',
  analysisCrmCustomerStudioCustomerDetail: '/v2b/analysis/crm/customer/studio-customer-detail',

  analysisCrmTransfer:'/v2b/analysis/crm/transfer/index',
  analysisCrmTransferAmount:'/v2b/analysis/crm/transfer/amount',
  analysisCrmTransferSankey:'/v2b/analysis/crm/transfer/sankey',

  analysisCrmFollow: '/v2b/analysis/crm/follow/index',
  analysisCrmFollowRank: '/v2b/analysis/crm/follow/rank',
  analysisCrmFollowExport: '/v2b/analysis/crm/follow/export',
  analysisCrmFollowCount: '/v2b/analysis/crm/follow/count',
  analysisCrmFollowContactType: '/v2b/analysis/crm/follow/contact-type',
  analysisCrmFollowChannel: '/v2b/analysis/crm/follow/channel',
  analysisCrmFollowHeatmap: '/v2b/analysis/crm/follow/heatmap',

  analysisAce: '/v2b/analysis/ace/index',
  analysisAceBar: '/v2b/analysis/ace/bar',
  analysisAceStock: '/v2b/analysis/ace/stock',
  analysisAceTimeline: '/v2b/analysis/ace/timeline',
  analysisAceRank: '/v2b/analysis/ace/rank',
  analysisAceIncome: '/v2b/analysis/ace/income',
  analysisAceIncomeCount: '/v2b/analysis/ace/income-count',
  analysisAceDaily: '/v2b/analysis/ace/daily',
  analysisAceCompare: '/v2b/analysis/ace/compare',
  

  analysisSummate: '/v2b/analysis/course/summate',
  analysisCourseCount: '/v2b/analysis/course/count',
  analysisSaleStatis: '/v2b/analysis/course/sale-statis',
  analysisContractStatis: '/v2b/analysis/course/contract-statis',
  analysisRefundStatis: '/v2b/analysis/course/refund-statis',
  analysisRefundContractStatis: '/v2b/analysis/course/refund-contract-statis',
  analysisChannelStatis: '/v2b/analysis/course/channel-statis',

  analysisClientsSubChannel: '/v2b/analysis/clients/sub-channel',
  analysisClientsChannelStatis: '/v2b/analysis/clients/channel-statis',
  analysisClientsChannelAchievement: '/v2b/analysis/clients/channel-achievement',
  analysisClientsChannelSummate: '/v2b/analysis/clients/channel-summate',
  analysisClientsEmployeeAchievement: '/v2b/analysis/clients/employee-achievement',
  analysisClientsEmployeeStatis: '/v2b/analysis/clients/employee-statis',
  analysisClientsFollowStatis: '/v2b/analysis/clients/follow-statis',
  analysisClientsAchievementSummate: '/v2b/analysis/clients/achievement-summate',

  analysisAbsence: '/v2b/analysis/absence/index',
  analysisAbsenceCourse: '/v2b/analysis/absence/course',
  analysisAbsenceSummate: '/v2b/analysis/absence/summate',
  analysisAbsenceCount: '/v2b/analysis/absence/count',
  analysisAbsenceAmount: '/v2b/analysis/absence/amount',

  analysisClassesIndex: '/v2b/analysis/classes/index',
  analysisClassesCount: '/v2b/analysis/classes/count',


  analysisEducationSummary:'/v2b/analysis/education/summary/index',
  analysisEducationSummaryExport:'/v2b/analysis/education/summary/export',


  analysisEducationMessage:'/v2b/analysis/education/message/index',
  analysisEducationMessageExport:'/v2b/analysis/education/message/export',

  analysisAudition: '/v2b/analysis/education/audition/index',
  analysisAuditionChannel: '/v2b/analysis/education/audition/channel',
  analysisAuditionExport: '/v2b/analysis/education/audition/export',
  analysisAuditionSankey: '/v2b/analysis/education/audition/sankey',
  analysisAuditionRank: '/v2b/analysis/education/audition/rank',

  analysisTeacher: '/v2b/analysis/teacher/index',
  analysisTeacherSummate: '/v2b/analysis/teacher/summate',
  analysisTeacherCourse: '/v2b/analysis/teacher/course',
  analysisTeacherBook: '/v2b/analysis/teacher/book',
  analysisTeacherCount: '/v2b/analysis/teacher/count',
  analysisTeacherChart: '/v2b/analysis/teacher/chart',
  analysisTeacherHeatmap: '/v2b/analysis/teacher/heatmap',
  analysisTeacherStudio: '/v2b/analysis/teacher/studio',

  analysisStockIncome: '/v2b/analysis/stock/income',
  analysisStockOutcome: '/v2b/analysis/stock/outcome',
  analysisStockCount: '/v2b/analysis/stock/count',

  //alan.channel
  analysisCrmChannel: '/v2b/analysis/crm/channel/index',
  analysisCrmChannelCount: '/v2b/analysis/crm/channel/count',

  analysisCrmChannelCycle: '/v2b/analysis/crm/channel/cycle',
  analysisCrmChannelAchievement: '/v2b/analysis/crm/channel/achievement',
  analysisCrmChannelAchievementChannel: '/v2b/analysis/crm/channel/achievement-channel',
  analysisCrmChannelContractCycle: '/v2b/analysis/crm/channel/contract-cycle',

  analysisCrmChannelSubChannel: '/v2b/analysis/crm/channel/sub-channel',
  analysisCrmChannelIndexStatis: '/v2b/analysis/crm/channel/index-statis',
  analysisCrmChannelSubChannelStatis: '/v2b/analysis/crm/channel/sub-channel-statis',

  analysisRankingLeads: '/v2b/analysis/ranking/leads/index',
  analysisRankingCustomer: '/v2b/analysis/ranking/customer/index',
  analysisRankingFollow: '/v2b/analysis/ranking/follow/index',
  analysisRankingTeacher: '/v2b/analysis/ranking/teacher/index',
  analysisRankingTeacherRevenue: '/v2b/analysis/ranking/teacher/revenue',
  analysisRankingTeacherStudioRevenue: '/v2b/analysis/ranking/teacher/studio-revenue',
  analysisRankingInvited: '/v2b/analysis/ranking/invited/index',
  analysisGoal: '/v2b/analysis/goal/index',
  analysisGoalStudioContractRevenue: '/v2b/analysis/goal/studio-contract-revenue',
  analysisGoalEmployeeContractRevenue: '/v2b/analysis/goal/employee-contract-revenue',
  analysisGoalStudioCourseRevenue: '/v2b/analysis/goal/studio-course-revenue',
  

  analysisWallet:'/v2b/analysis/wallet/index',
  analysisWalletCount:'/v2b/analysis/wallet/count',
  analysisWalletStudio:'/v2b/analysis/wallet/studio',

  analysisFinance: '/v2b/analysis/finance/index',
  analysisFinanceCount: '/v2b/analysis/finance/count',
  analysisFinanceRefund: '/v2b/analysis/finance/refund',

  analysisMeeting: '/v2b/analysis/meeting/index',
  analysisMeetingBooked: '/v2b/analysis/meeting/booked',
  analysisMeetingChecked: '/v2b/analysis/meeting/checked',
  analysisContent: '/v2b/analysis/content/index',
  analysisSurvey: '/v2b/analysis/survey/index',
  analysisSurveySubmit: '/v2b/analysis/survey/submit',

  analysisCoupon: '/v2b/analysis/coupon/index',
  
  analysisWorkJournal: '/v2b/analysis/work/journal/index',
  analysisWorkTask: '/v2b/analysis/work/task/index',
  analysisWorkApprove: '/v2b/analysis/work/approve/index',

  // 数据导入
  importImport: '/v2b/import/import/index',
  importImportUpload: '/v2b/import/import/upload',
  importImportTemplate: '/v2b/import/import/template',
  importImportImportLog: '/v2b/import/import/import-log',
  importImportCheckImport: '/v2b/import/import/check-import',
  importImportDeleteImport: '/v2b/import/import/delete-import',
  importImportImportTimeExample: '/v2b/import/import/import-time-example',
  importImportImportDateExample: '/v2b/import/import/import-date-example',
  importImportImportExample: '/v2b/import/import/import-example',

  // 表单收集
  surveyCenter: '/v2b/survey/center/index',
  surveyCenterAdd: '/v2b/survey/center/add',
  surveyCenterDetail: '/v2b/survey/center/detail',
  surveyCenterUpdate: '/v2b/survey/center/update',
  surveyCenterDelete: '/v2b/survey/center/delete',
  surveyCenterFormdata: '/v2b/survey/center/formdata',
  surveyCenterExport: '/v2b/survey/center/export',
  surveyQuestionAdd: '/v2b/survey/question/add',
  surveyQuestionDetail: '/v2b/survey/question/detail',
  surveyQuestionUpdate: '/v2b/survey/question/update',
  surveyQuestionType: '/v2b/survey/question/type',
  surveyQuestionValidate: '/v2b/survey/question/validate',
  surveyQuestionDelete: '/v2b/survey/question/delete',
  surveyQuestionAddAnswer: '/v2b/survey/question/add-answer',
  surveyQuestionUpdateAnswer: '/v2b/survey/question/update-answer',
  surveyQuestionDeleteAnswer: '/v2b/survey/question/delete-answer',

  // 在线商城
  storeProductCategory: '/v2b/store/product-category/index',
  storeProductCategoryAdd: '/v2b/store/product-category/add',


  //供应商管理
  storeSuppliers:'/v2b/store/suppliers/index',
  storeSuppliersStock:'/v2b/store/suppliers-stock/index',
  storeSuppliersStockIn:'/v2b/store/suppliers-stock/in',
  storeSuppliersStockDelete:'/v2b/store/suppliers-stock/delete',
  storeSuppliersAdd:'/v2b/store/suppliers/add',
  storeSuppliersDelete:'/v2b/store/suppliers/delete',
  storeSuppliersUpdate:'/v2b/store/suppliers/update',

  storeProductCategoryDetail: '/v2b/store/product-category/detail',
  storeProductCategoryUpdate: '/v2b/store/product-category/update',
  storeProductCategoryDelete: '/v2b/store/product-category/delete',
  storeProductSpec: '/v2b/store/product-spec/index',
  storeProductSpecAdd: '/v2b/store/product-spec/add',
  storeProductSpecDetail: '/v2b/store/product-spec/detail',
  storeProductSpecUpdate: '/v2b/store/product-spec/update',
  storeProductSpecDelete: '/v2b/store/product-spec/delete',
  storeProductSpecDeleteDet: '/v2b/store/product-spec/delete-det',
  storeProduct: '/v2b/store/product/index',
  storeProductAdd: '/v2b/store/product/add',
  storeProductDetail: '/v2b/store/product/detail',
  storeProductSpcs: '/v2b/store/product/specs',
  storeProductUpdate: '/v2b/store/product/update',
  storeProductDelete: '/v2b/store/product/delete',
  storeProductRecovery: '/v2b/store/product/recovery',
  storeOrder: '/v2b/store/order/index',
  storeOrderDetail: '/v2b/store/order/detail',
  storeOrderExpress: '/v2b/store/order/express',
  storeOrderExpressCode: '/v2b/store/order/express-code',
  storeOrderDelete: '/v2b/store/order/delete',
  storeRefund: '/v2b/store/refund/index',
  storeCoupon: '/v2b/store/coupon/index',
  storeCouponAdd: '/v2b/store/coupon/add',
  storeCouponDetail: '/v2b/store/coupon/detail',
  storeCouponUpdate: '/v2b/store/coupon/update',
  storeCouponDelete: '/v2b/store/coupon/delete',
  storeCouponSend: '/v2b/store/coupon/send',
  storeComment: '/v2b/store/comment/index',
  storeCommentDelete: '/v2b/store/comment/delete',
  storeCredit: '/v2b/store/credit-exchanges/index',
  storeCreditAdd: '/v2b/store/credit-exchanges/add',
  storeCreditDelete: '/v2b/store/credit-exchanges/delete',
  
  // 小工具
  toolsQrcode: '/v2b/tools/qrcode/index',
  toolsClick: '/v2b/tools/click/index',
  toolsShorturl: '/v2b/tools/shorturl/index',
  toolsSharePosterAdd: '/v2b/tools/share-poster/add-poster',
  toolsSharePosterDetail: '/v2b/tools/share-poster/detail',

  // 定制化-昂立慧动
  huidong: '/v2b/custom/huidong/apply/index',
  huidongCheck: '/v2b/custom/huidong/apply/check',
  huidongExport: '/v2b/custom/huidong/apply/export',
  huidongDelete: '/v2b/custom/huidong/apply/delete',
  huidongSendNotify: '/v2b/custom/huidong/apply/send-notify',
  huidongApplyDetail: '/v2b/custom/huidong/apply/detail',
  huidongApplyUpdate: '/v2b/custom/huidong/apply/update',
  huidongApplyPreview: '/v2b/custom/huidong/apply/preview',
  huidongReportEmployee: '/v2b/custom/huidong/report/employee',
  huidongReportEmployeeWeek: '/v2b/custom/huidong/report/employee-week',
  huidongReportStudio: '/v2b/custom/huidong/report/studio',
  huidongReportStudioWeek: '/v2b/custom/huidong/report/studio-week',
  huidongReportChannel: '/v2b/custom/huidong/report/channel',
  huidongReportHotLeads: '/v2b/custom/huidong/report/hot-leads',
  huidongReportHotLeadsFollow: '/v2b/custom/huidong/report/hot-leads-follow',
  huidongPrint: '/v2b/custom/huidong/print/index',
  huidongPrintWeek: '/v2b/custom/huidong/print/week',
  huidongPrintDelete: '/v2b/custom/huidong/print/delete',
  huidongPrintExport: '/v2b/custom/huidong/print/export',

  // 定制化-筑桥
  zhuqiaoApply: '/v2b/custom/zhuqiao/apply/index',
  zhuqiaoApplyDetail: '/v2b/custom/zhuqiao/apply/detail',
  zhuqiaoApplyUpdate: '/v2b/custom/zhuqiao/apply/update',
  zhuqiaoApplyExport: '/v2b/custom/zhuqiao/apply/export',

  // 日报
  oaIndex: '/v2b/oa/journal/index',
  oaAdd: '/v2b/oa/journal/add',
  oaDetail: '/v2b/oa/journal/detail',
  oaUpdate: '/v2b/oa/journal/update',
  oaDelete: '/v2b/oa/journal/delete',
  oaSeen: '/v2b/oa/journal/seen',
  oaAddComment: '/v2b/oa/journal/add-comment',
  journalTodayCrm: '/v2b/oa/journal/crm',
  journalRecord: '/v2b/oa/journal/record',
  journalExport: '/v2b/oa/journal/export',

  // 公告
  oaAnnounceIndex: '/v2b/oa/announce/index',
  oaAnnounceAdd: '/v2b/oa/announce/add',
  oaAnnounceSeen: '/v2b/oa/announce/seen',

  // 到访登记
  stageIndex: '/v2b/stage/attend/index',
  stageExport: '/v2b/stage/attend/export',
  stageAttend: '/v2b/stage/attend/attend',

  stageAttendSchedule: '/v2b/stage/attend/schedule',
  stageAttendScheduleExport: '/v2b/stage/attend/schedule-export',
  stageNoShow: '/v2b/stage/attend/no-show',

  // 审核
  oaExamineAdd: '/v2b/oa/examine/add',
  oaExamineIndex: '/v2b/oa/examine/index',
  oaExamineDetail: '/v2b/oa/examine/detail',
  oaExamineUpdate: '/v2b/oa/examine/update',
  oaExamineRevoke: '/v2b/oa/examine/revoke',
  oaExamineCheck: '/v2b/oa/examine/check',
  oaExamineStep: '/v2b/oa/examine/step',
  oaExamineCheckRecord: '/v2b/oa/examine/check-record',

  // 任务
  oaTaskIndex: '/v2b/oa/task/index',
  oaTaskAdd: '/v2b/oa/task/add',
  oaTaskDeatil: '/v2b/oa/task/detail',
  oaTaskUpdate: '/v2b/oa/task/update',
  oaTaskDelete: '/v2b/oa/task/delete',
  oaTaskCheck: '/v2b/oa/task/check',
  oaTaskLog: '/v2b/oa/task/log',
  oaTaskAddLog: '/v2b/oa/task/add-log',
  oaTaskReOrder: '/v2b/oa/task/re-order',

  // 更新日志
  oaUpgradeLog: '/v2b/oa/upgrade-log/index',

  // 企业话术
  discourseGroup: '/v2b/crm/discourse/group',
  discourseAddGroup: '/v2b/crm/discourse/add-group',
  discourseUPdateGroup: '/v2b/crm/discourse/update-group',
  discourseDeleteGroup: '/v2b/crm/discourse/delete-group',
  discourseGroupSort: '/v2b/crm/discourse/group-sort',
  discourseIndex: '/v2b/crm/discourse/index',
  discourseAdd: '/v2b/crm/discourse/add',
  discourseDetail: '/v2b/crm/discourse/detail',
  discourseUpdate: '/v2b/crm/discourse/update',
  discourseDelete: '/v2b/crm/discourse/delete',
  discourseClick: '/v2b/crm/discourse/click',
  discourseLeads: '/v2b/crm/discourse/leads',
  discourseLeadsAdd: '/v2b/crm/discourse/leads-add',
  discourseCustomer: '/v2b/crm/discourse/customer',
  discourseCustomerAdd: '/v2b/crm/discourse/customer-add',

  // 线索留资
  collectIndex: '/v2b/crm/collect/index',
  collectAdd: '/v2b/crm/collect/add',
  collectDetail: '/v2b/crm/collect/detail',
  collectUpdate: '/v2b/crm/collect/update',
  collectLeads: '/v2b/crm/collect/leads',

  // 信息群发
  missionSingle: '/v2b/crm/mission/single',
  missionSingleCount: '/v2b/crm/mission/count',
  missionAddSingle: '/v2b/crm/mission/add-single',
  missionSyncSingle: '/v2b/crm/mission/sync-single',
  missionUploadmedia: '/v2b/crm/mission/upload-media',
  missionGroup: '/v2b/crm/mission/group',
  missionAddGroup: '/v2b/crm/mission/add-group',
  missionSyncGroup: '/v2b/crm/mission/sync-group',
  missionMoment: '/v2b/crm/mission/moment',
  missionSyncMoment: '/v2b/crm/mission/sync-moment',
  missionMomentUserList: '/v2b/crm/mission/moment-user-list',
  missionMomentLikes: '/v2b/crm/mission/moment-likes',
  missionMomentComments: '/v2b/crm/mission/moment-comments',
  missionSingleUserList: '/v2b/crm/mission/single-user-list',
  missionGroupList: '/v2b/crm/mission/group-list',
  
  // 工单系统
  smartserviceIndex: '/v2b/smartservice/order/index',
  smartserviceAdd: '/v2b/smartservice/order/add',
  smartserviceUpdate: '/v2b/smartservice/order/update',
  smartserviceDetail: '/v2b/smartservice/order/detail',
  smartserviceClose: '/v2b/smartservice/order/close',
  smartserviceVersions: '/v2b/smartservice/order/versions',
  smartserviceAddReply: '/v2b/smartservice/order/add-reply',
  enterpriseWorkOrder: '/v2b/enterprise/work-order/index',
  enterpriseWorkCustomer: '/v2b/enterprise/work-order/customer',
  enterpriseWorkAddCustomer: '/v2b/enterprise/work-order/add-customer',
  enterpriseWorkDelCustomer: '/v2b/enterprise/work-order/del-customer',
  enterpriseWorkOrderMine: '/v2b/enterprise/work-order/mine',
  enterpriseWorkOrderDetail: '/v2b/enterprise/work-order/detail',
  enterpriseWorkOrderWorker: '/v2b/enterprise/work-order/worker',
  enterpriseWorkOrderAssign: '/v2b/enterprise/work-order/assign',
  enterpriseWorkOrderFinish: '/v2b/enterprise/work-order/finish',
  enterpriseWorkOrderVersions: '/v2b/enterprise/work-order/versions',
  enterpriseWorkOrderAddReply: '/v2b/enterprise/work-order/add-reply',
  enterpriseWorkOrderAnalysis: '/v2b/enterprise/work-order/analysis',

  enterpriseFilterApp: '/v2b/enterprise/filter/app',
  enterpriseFilterBelongStudio: '/v2b/enterprise/filter/get-belong-studio',

  // RSS
  labsRss: '/v2b/labs/rss/index',
  labsRssAdd: '/v2b/labs/rss/add',
  labsRssDelete: '/v2b/labs/rss/delete',
  labsRssClick: '/v2b/labs/rss/click',
  labsRssChannel: '/v2b/labs/rss/channel',
  labsRssDeleteChannel: '/v2b/labs/rss/delete-channel',

  // SOP
  sopIndex: '/v2b/sop/sop/index',
  sopAdd: '/v2b/sop/sop/add',
  sopDelete: '/v2b/sop/sop/delete',
  sopDetail: '/v2b/sop/sop/detail',
  sopGroup: '/v2b/sop/sop/group',
  sopGroupSort: '/v2b/sop/sop/group-sort',
  sopAddGroup: '/v2b/sop/sop/add-group',
  sopUpdateGroup: '/v2b/sop/sop/update-group',
  sopDeleteGroup: '/v2b/sop/sop/delete-group',

  //Monitor

  monitorIndex:'/v2b/monitor/monitor/index',
  monitorAdd:'/v2b/monitor/monitor/add',
  monitorUpdate:'/v2b/monitor/monitor/update',
  monitorRefreash:'/v2b/monitor/monitor/refresh',
  monitorRecallIndex:'/v2b/monitor/recall/index',

}

export default url